import React, { Fragment, useEffect } from 'react'

import { useSnackAlert } from '../../components/snackAlert/SnackAlert'
import { emptyErrorMessage } from '../../redux/axiosClient/IsErrorSlice'
import { useAppSelector, useAppDispatch } from '../../redux/store/hooks'

function ErrorIndicator() {
    const dispatch = useAppDispatch()
    const { setSeverity, setOpen, setMessage } = useSnackAlert()
    const { status, statusText } = useAppSelector((state) => state.isError)
    useEffect(() => {
        if (status && statusText) {
            setSeverity('error')
            setOpen(true)
            setMessage(statusText)
            dispatch(emptyErrorMessage())
        }
    }, [status, statusText])
    return (
        <Fragment>{""}</Fragment>
    )
}

export default ErrorIndicator;