import React, { Fragment, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import CachedIcon from '@mui/icons-material/Cached'
import InputAdornment from '@mui/material/InputAdornment'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import Loader from '../../components/Loader/Loader'

interface searchSelectedQueryProps {
    label: string
    loading: boolean
    disabled?: boolean
    selectedQuery: string | null
    apiDispatchFunction: () => void
    setSelectedQuery: (text: string | null) => void
    dropdown: { id: number, label: string, value: string | number }[]
}

function SearchSelectedQuery(props: searchSelectedQueryProps) {
    const { label, loading, selectedQuery, setSelectedQuery, dropdown, disabled, apiDispatchFunction } = props
    useEffect(() => {
        if (selectedQuery !== null) {
            const debounceTime = setTimeout(() => {
                apiDispatchFunction()
            }, 500);
            return () => clearTimeout(debounceTime)
        }
    }, [selectedQuery])


    const handleReset = () => {
        setSelectedQuery(null)
        apiDispatchFunction()
    }

    return (
        <Fragment>
            <FormControl fullWidth size='small'>
                <TextField
                    select
                    fullWidth
                    size='small'
                    label={label}
                    value={selectedQuery}
                    onChange={(event) => setSelectedQuery(event.target.value)}

                    InputProps={{
                        sx: { borderRadius: "10px", height: "37px", width: "auto", minWidth: "185px", maxWidth: "245px", fontSize: "14.5px", fontWeight: 500 },
                        endAdornment: (
                            <InputAdornment position='start' sx={{ marginRight: -1.5, }}>
                                {
                                    loading ? <Box component="span" sx={{ mr: 1, mt: 0.5 }}><Loader padding="0 0" size={20} /></Box> :
                                        <IconButton onClick={handleReset}>
                                            <CachedIcon fontSize='small' sx={{ ":hover": { color: (theme) => theme.palette.primary.main } }} />
                                        </IconButton>
                                }
                            </InputAdornment>
                        )
                    }}
                    SelectProps={{
                        IconComponent: (props) => (
                            <SvgIcon {...props} sx={{ ml: -8, mr: 3 }}>
                                <ArrowDropDownIcon />
                            </SvgIcon>
                        ),
                        label
                    }}
                    InputLabelProps={{
                        sx: {
                            transform: 'translate(12px, 7px) scale(0.91)', // Label Position When Not Focused
                            '&.MuiInputLabel-shrink': {
                                transform: 'translate(14px, -8px) scale(0.65)', // Label Position When Focused
                            },
                            fontWeight: 400,
                        },
                        shrink: !!selectedQuery,
                    }}
                >
                    {dropdown.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>

        </Fragment >
    )
}

export default SearchSelectedQuery;

//Important Link ::: https://stackoverflow.com/questions/63047684/material-ui-select-menu-with-end-adornment

