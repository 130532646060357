import React, { useMemo, useState } from 'react'

import malePic from '../../asset/authentication/malePic.png'
import felamePic from '../../asset/authentication/femalePic.png'

interface currentUser {
  name: string
  email: string
  gender: string
  userType: string
  userImage: string
}

function CurrentUser() {
  const [currentUser, setCurrentUser] = useState<currentUser>(
    { name: "", email: "", userImage: "", gender: "", userType: "" })
  useMemo(() => {
    const currentUserDetails = JSON.parse(localStorage.getItem("userDetails") || "")
    setCurrentUser({
      userImage: currentUserDetails?.gender === "male" ? malePic : felamePic,
      name: currentUserDetails?.name,
      email: currentUserDetails?.email,
      gender: currentUserDetails?.gender,
      userType: currentUserDetails?.userType,
    })
  }, [localStorage.getItem("userDetails")])

  return { currentUser }
}

export default CurrentUser;