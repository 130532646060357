import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from "../../../../axiosClient/AxiosClient"

interface statusUpdate { id: number, status: "active" | "inactive" }
interface applyParams { search?: string | null, status?: "active" | "inactive", page?: number, limit?: number }

export const createTestSeriesAsync = createAsyncThunk(
    "create/test-series", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/test-series", payload, toolkit)
    }
)

export const getAllTestSeriesAsync = createAsyncThunk(
    "read/test-series", async (params: applyParams, toolkit) => {
        return await AxiosClient("GET", "/test-series", [], toolkit, params)
    }
)

export const getTestSeriesByIDAsync = createAsyncThunk(
    "readID/test-series", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/test-series/${payload.id}`, [], toolkit)
    }
)

export const updateTestSeriesAsync = createAsyncThunk(
    "update/test-series", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/test-series/${payload.id}`, payload.formData, toolkit)
    }
)

export const deleteTestSeriesAsync = createAsyncThunk(
    "delete/test-series", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/test-series/${payload.id}`, [], toolkit)
    }
)

export const testSeriesStatusUpdateAsync = createAsyncThunk(
    "statusUpdate/test-series/status", async (payload: statusUpdate, toolkit) => {
        return await AxiosClient("POST", "/test-series/update/status", payload, toolkit)
    }
)