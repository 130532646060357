import React, { Fragment, useContext, useEffect } from 'react'
import classes from '../styles/credentials.module.css'

import { formContext } from '../Authentication'
import { Formik, Form } from 'formik'
import { useSnackAlert } from '../../components/snackAlert/SnackAlert'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LoginIcon from '@mui/icons-material/Login'
import EmailIcon from "@mui/icons-material/Email"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'

import FormSalutation from './FormSalutation'
import LoadingButton from '../../components/LoadingButton/LoadingButton'

import { AuthInputField } from '../../custom/inputComponent'
import { forgetPasswordState, forgetPasswordInitialValues, forgetPasswordValidationSchema } from "./utils";

// API Section :::
import { authLogin, saveCurrentUserDetails } from '../../redux/slice/auth/authAsync'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'


function ForgetPassword() {
    const context = useContext(formContext)
    const { setLoginForm, setRegistrationForm, setForgetPasswordForm } = context;

    const onSubmit = async (values: forgetPasswordState) => {
        console.log("Login Values", values);
    };

    return (
        <Fragment>
            <Stack className={classes.forgetPasswordContainer}>

                <FormSalutation text='Recover Password' />

                <Box className={classes.forgetPasswordFormSection}>

                    <Formik Formik initialValues={forgetPasswordInitialValues} validationSchema={forgetPasswordValidationSchema} onSubmit={onSubmit}>
                       
                        {(Formik) => {
                            return (
                                <Form className={classes.formContent}>

                                    <AuthInputField<forgetPasswordState> name='email' type='email' label='Email'
                                        Formik={Formik} loading={false} icon={EmailIcon} sx={{ marginBottom: 1 }}
                                    />

                                    <Box className={classes.loadingButton}>
                                        <LoadingButton loading={false} StartIcon={SettingsSuggestIcon}
                                            variant='contained' buttonText='Reset Password' loadingPosition='start'
                                        />
                                    </Box>

                                    <Box className={classes.formRedirect}>
                                        <Typography
                                            className={classes.formRedirectContent}
                                            onClick={() => { setLoginForm(true); setRegistrationForm(false); setForgetPasswordForm(false) }}
                                        >
                                            Login <LoginIcon fontSize='small' />
                                        </Typography>

                                        <Typography
                                            className={classes.formRedirectContent}
                                            onClick={() => { setLoginForm(false); setRegistrationForm(true); setForgetPasswordForm(false) }}
                                        >
                                            Register <HowToRegIcon fontSize='small' />
                                        </Typography>
                                    </Box>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </Stack>
        </Fragment>
    )
}

export default ForgetPassword