import React, { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'

import { styled, alpha } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'


const StyledDropZone = styled("div")(({ theme }) => ({
    width: 64,
    fontSize: 24,
    flexShrink: 0,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.disabled,
    backgroundColor: alpha(theme.palette.grey[500], 0.08),
    "&:hover": {
        opacity: 0.72,
    },
}));

const StyledComponent = styled("div")(({ theme }) => ({
    fontSize: 24,
    display: "flex",
    flexWrap: 'wrap',
    cursor: "pointer",
    columnGap: '20px',
    alignItems: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "flex-start",
    color: theme.palette.text.secondary,
    border: `solid 2px ${theme.palette.divider}`,
    "&:hover": {
        opacity: 0.72,
    },
}));

interface fileUploadProps {
    file: any
    accept: any
    name: string
    label: string
    error: boolean
    disabled?: boolean
    otherFile?: boolean
    onDrop: (acceptedFiles: File[]) => void
}

export default function FileUpload(props: fileUploadProps) {
    const { disabled, error, file, label, otherFile, accept, name, ...others } = props


    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: accept,
        disabled, ...others
    })

    const isError = isDragReject || !!error
    const imageURL = typeof file === "string" ? file : file?.preview
    return (
        <Fragment>
            <StyledComponent
                {...getRootProps()}
                sx={{
                    height: 55,
                    width: "100%",
                    ...(isError && { color: "error.main", borderColor: "error.light" }),
                }}
            >

                <StyledDropZone
                    sx={{
                        height: "100%",
                        width: "auto",
                        p: 2,
                        ...(isDragActive && { opacity: 0.72, }),
                        ...(isError && { color: "error.main", bgcolor: "error.lighter", borderColor: "error.light" }),
                        ...(disabled && { opacity: 0.48, pointerEvents: "none" }),
                        ...(imageURL && { borderRadius: "8px", border: (theme) => `solid 1px ${theme.palette.divider}` }),
                        ...(isError && { backgroundColor: 'transparent' }),

                    }}
                >
                    <input {...getInputProps()} />

                    {
                        Boolean(file?.preview || typeof file === "string") ?
                            <DescriptionOutlinedIcon fontSize='large' />
                            : <CloudUploadTwoToneIcon fontSize='large' />
                    }

                </StyledDropZone>

                <Typography sx={{ mr: { xs: 0, sm: 1 } }}>
                    {
                        Boolean(file?.preview) ? file.name.slice(0, 20)
                            : typeof file === "string" ? file.slice(0, 44)
                                : `Upload ${label?.slice(0, 40)}` || "Upload Files"
                    }
                </Typography>
            </StyledComponent>
        </Fragment >
    )
};