import React, { Fragment } from 'react'
import { useThemeContext } from '../ThemeContextProvider'

import IconButton from '@mui/material/IconButton'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'


export function ModeContraller() {
    const { mode, changeMode } = useThemeContext()
    return (
        <Fragment>
            <IconButton sx={{ ml: 1 }} onClick={changeMode} color="inherit">
                {mode === "dark" ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
        </Fragment>
    )
};

