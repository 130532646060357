import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import { ControlPanelPaths } from '../../../routes/zRoutePaths/routePaths'

function ControlPanelFooter() {
    const navigate = useNavigate()
    return (
        <Fragment>
            <Container>
                <Stack
                    onClick={() => navigate(ControlPanelPaths.TermsCondition)}
                    sx={{
                        mt: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
                        flexWrap: "wrap", cursor: "pointer", ":hover": { color: (theme) => theme.palette.primary.main }
                    }}>
                    <Typography sx={{ fontSize: "12px", textAlign: "center", fontWeight: "600" }}>&copy; Pariksha Mantra • All Rights Received</Typography>

                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>&nbsp;•&nbsp;Terms & Conditions</Typography>
                </Stack>
            </Container>
        </Fragment>
    )
}

export default ControlPanelFooter;