import React, { Fragment, SyntheticEvent, createContext, useContext, useState } from 'react'

import { Snackbar, Alert, Transitions, Slide, Typography } from '@mui/material'

interface snackAlertContextProps {
    severity: "success" | "info" | "warning" | "error"
    message: string
    open: boolean
    setOpen: (status: boolean) => void
    setMessage: (message: string) => void
    setSeverity: (severity: "success" | "info" | "warning" | "error") => void
}

// <----- Creating Context ----->
const SnackAlertContext = createContext<snackAlertContextProps>({
    severity: "success",
    message: "",
    open: false,
    setOpen: () => { },
    setMessage: () => { },
    setSeverity: () => { }
})

// <----- Wrapper/ Provider ----->
interface snackAlertProps {
    children: React.ReactNode
}
export const SnackAlertProvider = (props: snackAlertProps) => {
    const { children } = props

    // All "snackAlertContextProps" are created in form of state 
    const [open, setOpen] = useState<boolean>(false)
    const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error">("success")
    const [message, setMessage] = useState("")


    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    return (
        <Fragment>
            <SnackAlertContext.Provider value={{ severity, message, open, setOpen, setMessage, setSeverity }}>
                {children}
                {open && (
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        TransitionComponent={(props) => <Slide {...props} direction='left' />}
                    >
                        <Alert
                            variant="standard"
                            severity={severity}
                            onClose={handleClose}
                            sx={{ width: '100%' }}
                        >
                            <Typography>{message}</Typography>
                        </Alert>
                    </Snackbar>
                )}
            </SnackAlertContext.Provider>
        </Fragment>
    )
}

// <----- Using Context ------>
export const useSnackAlert = () => {
    return useContext(SnackAlertContext)
}





