import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { SvgIconProps } from '@mui/material/SvgIcon'
import InputAdornment from '@mui/material/InputAdornment'

import Loader from '../../components/Loader/Loader'

interface singleButtonTextInputProps<initialState> {
    label: string
    loading: boolean
    disabled?: boolean
    placeholder?: string
    shouldShrink?: boolean
    sx: React.CSSProperties
    name: keyof initialState
    executionFun: () => void
    Formik: FormikProps<initialState>
    type: React.HTMLInputTypeAttribute
    icon: React.ElementType<SvgIconProps>
}

function SingleButtonTextInput<initialState>(props: singleButtonTextInputProps<initialState>) {
    const { name, label, icon: MUIIcon, type, Formik, loading, disabled, shouldShrink, placeholder, executionFun, sx } = props

    return (
        <Fragment>
            <FormControl fullWidth>
                <TextField
                    fullWidth
                    type={type}
                    sx={{ ...sx }}
                    disabled={disabled}
                    name={name as string}
                    placeholder={placeholder}
                    value={Formik.values[name]}
                    onChange={Formik.handleChange}
                    InputLabelProps={{ shrink: shouldShrink }}
                    InputProps={{
                        endAdornment:
                            (<InputAdornment position="end">
                                <MUIIcon color='primary' cursor='pointer' onClick={() => executionFun()} />
                            </InputAdornment>)
                    }}
                    error={Boolean(Formik.touched[name] && Formik.errors[name])}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                />
            </FormControl>
        </Fragment>
    )
}

export default SingleButtonTextInput;