// Icon Imports:::
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import TokenIcon from '@mui/icons-material/Token';
import LoginIcon from '@mui/icons-material/Login';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

export const menuItems = [
    { id: 1, path: '/', name: 'Home', icon: HomeIcon, parent: "", subMenu: [] },
    { id: 2, path: '/questions', name: 'Questions', icon: QuestionAnswerIcon, parent: "", subMenu: [] },
    { id: 3, path: '/vacancies', name: 'Vacancies', icon: WorkIcon, parent: "", subMenu: [] },
    { id: 4, path: '/exam-info', name: 'Exams', icon: TokenIcon, parent: "", subMenu: [] },
    { id: 4, path: '/others', name: 'Others', icon: MiscellaneousServicesIcon, parent: "", subMenu: [] },
    { id: 4, path: '/login', name: 'Login', icon: LoginIcon, parent: "", subMenu: [] },
]