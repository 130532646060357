import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

// Create Vacancy:::
export const createVacancyAsync = createAsyncThunk(
    "createVacancy/vacancy", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/vacancy", payload, toolkit)
    }
)

// Get All Vacancies:::
interface examParams { page?: number, limit?: number, search?: string | null, status?: "active" | "inactive" }
export const getAllVacanciesAsync = createAsyncThunk(
    "getAllVacancies/vacancy", async (params: examParams, toolkit) => {
        return await AxiosClient("GET", "/vacancy", [], toolkit, params)
    }
);

// Get By ID:::
export const getVacancyByIDAsync = createAsyncThunk(
    "getVacancyByID/vacancy", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/vacancy/${payload.id}`, [], toolkit)
    }
)

// Update Vacancy:::
export const updateVacancyAsync = createAsyncThunk(
    "updateVacancy/vacancy", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/vacancy/${payload.id}`, payload.formData, toolkit)
    }
)

// Active/Inactive Status:::
export const vacancyCurrentStatusUpdateAsync = createAsyncThunk(
    "vacancyCurrentStatus/vacancy/status", async (payload: { id: number, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/vacancy/update/status", payload, toolkit)
    }
)

// Delete Vacancy:::
export const deleteVacancyAsync = createAsyncThunk(
    "deleteVacancy/vacancy", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/vacancy/${payload.id}`, [], toolkit)
    }
)