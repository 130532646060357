import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledPaper, hoverPrimaryColor } from '../../styledComponents/styledComponents'



interface imageViewerProps<initialState> {
    title: string
    name: keyof initialState
    Formik: FormikProps<initialState>
}

const flexColumn = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }


export default function ImageViewerMedium<initialState>(props: imageViewerProps<initialState>) {
    const { name, Formik, title } = props
    const image = Formik.values[name] as unknown as { preview: string }[];

    return (
        <Fragment>
            {
                image[0] &&
                <Box sx={{ ...flexColumn, gap: 1, ...hoverPrimaryColor }}>
                    <StyledPaper>
                        <img src={image[0]?.preview} alt={title}
                            style={{ maxWidth: "150px", height: "auto", borderRadius: "10px" }}
                        />
                    </StyledPaper>
                    <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
                </Box>
            }
        </Fragment>
    )
}