import { Components } from '@mui/material/styles'

const MuiLink: Components['MuiLink'] = {
    styleOverrides: {
        root: {
            textDecoration: 'none',
            fontFamily: 'Poppins',
            fontWeight: 400,
        }
    }
};

export default MuiLink;
