import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { sideNavBarProps, mainMenuProps, subMenu } from './components/interface'
import './components/sideNavBar.css'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import { Link as MUILink, } from '@mui/material'
import Typography from '@mui/material/Typography'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'

// Images Imports:::
import sideBarLogo from '../../asset/logos/sideBarLogo.svg'


import { SiteLogo } from '../../theme/controller/Logo'
import { ModeContraller } from '../../theme/controller/ModeController'
import { CurrentUser, logout } from '../../custom/accountAction'

function SideNavBar(props: sideNavBarProps) {
    const { isOpen, setIsOpen, menuItems } = props
    const { currentUser } = CurrentUser()
    const navigate = useNavigate()
    const { email, name, userImage } = currentUser
    const handleMenues = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Fragment>
            <Paper elevation={5} className={isOpen ? 'sideBar' : 'sideBar active'}>
                <div className={isOpen ? 'logoContainer' : 'logoContainer active'}>
                    <img src={sideBarLogo} alt="Logo" className={'logo'} />
                    {/* <SiteLogo /> */}
                    <h2 className={'title'}>Priksha Mantra</h2>
                </div>

                <div onClick={() => handleMenues()}
                    className={isOpen ? 'burgerContainer' : 'burgerContainer active'}>
                    <div className={'burgerTrigger'}></div>
                    <div className={'burgerMenu'}></div>
                </div>

                <div className={isOpen ? 'contentsContainer' : 'contentsContainer active'}>
                    <ul>
                        {
                            menuItems?.length && menuItems?.map((item) => {
                                return (
                                    <Fragment key={item.id}>
                                        <MainMenu
                                            id={item.id}
                                            name={item.name}
                                            subMenu={item.subMenu}
                                            path={item.path}
                                            icon={item.icon}
                                            isOpen={isOpen}
                                            parent={item.parent}
                                        />
                                    </Fragment>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className={isOpen ? 'profileContainer' : 'profileContainer active'}>
                    <img src={userImage} alt="Profile" />
                    <div className={'profileContents'}>
                        <Typography className={'name'}>{name}</Typography>
                        <Typography className={'email'}>{email}</Typography>
                    </div>
                </div>

                <div className='userPanelControl'>
                    <Box component='span' >
                        <ModeContraller />
                    </Box>
                    <Box component='span' className={isOpen ? 'accountAction' : 'accountAction active'}>
                        <Chip
                            icon={<SettingsSuggestOutlinedIcon fontSize='small' sx={{ position: 'relative', bottom: '1px' }} />}
                            label="Account" variant="outlined"
                            onClick={() => navigate("/user/account")}
                            sx={{ ml: 1, mr: 1 }}
                        />
                        <Chip
                            icon={<LogoutTwoToneIcon fontSize='small' sx={{ position: 'relative', bottom: '1px' }} />}
                            label="Logout" variant="outlined"
                            onClick={() => logout()}
                        />
                    </Box>
                </div>
            </Paper>
        </Fragment>
    )
}

export default SideNavBar;

const MainMenu = (props: mainMenuProps) => {
    const { id, name, path, icon: Icon, subMenu, isOpen, parent } = props
    const [show, setShow] = useState<boolean>(false)
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;

    useEffect(() => {
        if (!isOpen) {
            setShow(false)
        }
    }, [isOpen])

    return (
        // <Fragment>
        //     <li
        //         key={id}
        //         className={pathname === path ? 'active' : ''}
        //         style={{ width: '100%' }}
        //         onMouseEnter={() => setIsHovered(true)}
        //         onMouseLeave={() => setIsHovered(false)}
        //     >
        //         <Icon
        //             sx={{
        //                 marginLeft: '10px',
        //                 color: (isHovered || pathname === path || pathname?.includes(parent)) ? (theme) => theme.palette.primary.main : '',
        //                 cursor: 'pointer',
        //             }}
        //         />
        //         <MUILink
        //             onClick={() => path !== "" ? navigate(path) : null}
        //             className={'navigationLink'}
        //             style={{ width: '78%', cursor: 'pointer' }}
        //         >
        //             <Typography
        //                 sx={{
        //                     fontSize: '15px',
        //                     fontWeight: (pathname === path) ? 600 : pathname?.includes(parent) ? 500 : 400,
        //                     color: (isHovered || pathname === path || pathname?.includes(parent)) ? (theme) => theme.palette.primary.main : '',
        //                     display: 'flex',
        //                     flexDirection: 'row',
        //                     justifyContent: 'space-between',
        //                     alignItems: 'center',
        //                     width: '100%',
        //                     '&:hover': {
        //                         fontWeight: 500,
        //                     }
        //                 }}
        //                 onClick={() => setShow(!show)}
        //             >
        //                 {name}
        //                 {subMenu.length ?
        //                     show ?
        //                         <ExpandLessIcon fontSize='small' />
        //                         :
        //                         <NavigateNextRoundedIcon fontSize='small' />
        //                     : null}
        //             </Typography>
        //         </MUILink>
        //     </li>

        //     <span className={!show ? 'subMenu_display' : 'subMenu_display active'}>
        //         {
        //             subMenu.length ? (<SubMenu subMenu={subMenu} />) : null
        //         }
        //     </span>
        // </Fragment>
        <Fragment></Fragment>
    )
}


const SubMenu = (props: { subMenu: subMenu[] }) => {
    const { subMenu } = props
    const [isHovered, setIsHovered] = useState<number | null>(null)
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    return (
        <Fragment>
            <Box className="subMenuContainer">
                {
                    subMenu.map((sub) => {
                        return (
                            <Link
                                key={sub.id}
                                to={sub.path}
                                className={'subMenuitem'}
                                onMouseEnter={() => setIsHovered(sub.id)}
                                onMouseLeave={() => setIsHovered(null)}
                            >
                                <FiberManualRecordIcon
                                    sx={{
                                        fontSize: pathname === sub.path ? '12px' : '8px',
                                        ml: pathname === sub.path ? '-2.5px' : '',
                                        color: ((isHovered === sub.id) || pathname?.includes(sub.path)) ? (theme) => theme.palette.primary.main : ''
                                    }} />
                                <Typography
                                    sx={{
                                        fontWeight: pathname?.includes(sub.path) ? 600 : 400,
                                        color: pathname?.includes(sub.path) ? (theme) => theme.palette.primary.main : "",
                                        '&:hover': {
                                            fontWeight: pathname?.includes(sub.path) ? 600 : 500,
                                            color: (theme) => theme.palette.primary.main
                                        }
                                    }}>
                                    {sub.name}
                                </Typography>
                            </Link>
                        )
                    })
                }
            </Box>
        </Fragment>
    )
}





