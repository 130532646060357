import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

import Loader from '../../components/Loader/Loader'

interface textInputProps<FormValues> {
    label: string
    loading: boolean
    disabled?: boolean
    placeholder?: string
    name: keyof FormValues
    shouldShrink?: boolean
    Formik: FormikProps<FormValues>
    type?: React.HTMLInputTypeAttribute
}

function TextInput<FormValues>(props: textInputProps<FormValues>) {
    const { name, label, Formik, loading, disabled, placeholder, shouldShrink, type } = props

    return (
        <Fragment>
            <FormControl fullWidth disabled={loading}>
                <TextField
                    fullWidth
                    disabled={disabled}
                    sx={{ mb: "10px" }}
                    type={type || "text"}
                    name={name as string}
                    placeholder={placeholder}
                    value={Formik.values[name]}
                    onChange={Formik.handleChange}
                    InputLabelProps={{ shrink: shouldShrink }}
                    error={Boolean(Formik.touched[name] && Formik.errors[name])}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                />
            </FormControl>

        </Fragment>
    )
}

export default TextInput;