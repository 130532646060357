import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../../../navigation/sideNavBar/components/sideNavBar.css'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'

import malePic from '../../../../asset/authentication/malePic.png'
import felamePic from '../../../../asset/authentication/femalePic.png'

import { logout } from '../../../../custom/accountAction'
import { ModeContraller } from '../../../../theme/controller/ModeController'
import {
    ProfileContainer, NameEmailBox, NameField,
    EmailField, AccountActionContainer, AccountActionBox
} from '../styles/sideBarStyles'

// API Section :::
import { useAppSelector } from '../../../../redux/store/hooks'
import { Stack } from '@mui/material'


interface actionMenuProps { isOpen: boolean }
export default function ActionMenu({ isOpen }: actionMenuProps) {
    const navigate = useNavigate()
    const { responseData } = useAppSelector((state) => state.currentUserDetails)

    const userImageSetter = () => {
        if (responseData?.logo) {
            return responseData?.logo
        } else {
            return responseData?.gender === 'male' ? malePic : felamePic
        }
    }

    return (
        <Fragment>
            <ProfileContainer>
                <img src={userImageSetter()} alt="Profile Pictute" style={{ width: 'auto', height: '3rem' }} />
                <NameEmailBox {...{ isOpen }}>
                    <NameField>{responseData?.name}</NameField>
                    <EmailField>{responseData?.email}</EmailField>
                </NameEmailBox>
            </ProfileContainer>

            <AccountActionContainer>
                <Box component='span' > <ModeContraller /> </Box>
                <AccountActionBox {...{ isOpen }} component='span'>
                    <Chip
                        icon={<SettingsSuggestOutlinedIcon fontSize='small' sx={{ position: 'relative', bottom: '1px' }} />}
                        label="Account" variant="outlined" onClick={() => navigate("user/account")} sx={{ ml: 1, mr: 1 }}
                    />
                    <Chip
                        icon={<LogoutTwoToneIcon fontSize='small' sx={{ position: 'relative', bottom: '1px' }} />}
                        label="Logout" variant="outlined" onClick={() => logout()}
                    />
                </AccountActionBox>
            </AccountActionContainer>
        </Fragment>
    )
};