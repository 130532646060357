import axios from "axios";
import { isJson } from "./IsJson";
import { errorMessage } from "./IsErrorSlice";
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';


type params = object
type apiEndPoint = string
type toolKit = GetThunkAPI<AsyncThunkConfig>
type requestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE"

const AxiosClient = async (
    method: requestMethod,
    apiEndPoint: apiEndPoint,
    payload: any,
    toolkit: toolKit,
    params?: params,
    directPath: string | null = null,
    content = "multipart/form-data",
) => {

    // Get the token from localStorage
    const token = isJson(localStorage.getItem("auth") || "")
        ? JSON.parse(localStorage.getItem("auth") as string)?.token
        : null;

    // Set Authorization header with Bearer token
    const headers: { [key: string]: string } = {
        "Content-Type": content || "application/json",
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return await axios({
        method: method,
        url: directPath ? directPath : `${process.env.REACT_APP_API_URL}${apiEndPoint}`,
        data: payload,
        params: { ...params },
        headers: headers,
    })
        .then((response) => {
            return toolkit.fulfillWithValue(response.data)
        })
        .catch((error) => {
            toolkit.dispatch(errorMessage(error.response))
            return toolkit.rejectWithValue(error.response)
        })
};

export { AxiosClient };





