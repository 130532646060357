import { createSlice } from "@reduxjs/toolkit"
import { subHeading } from './interface'

interface SubjectFramework {
    id: number, modal_id: string, name: string, code: string,
    subheading: subHeading[],
    syllabus: { id: number, subject_name: string }
}

interface SyllabusUtilityStates {
    allHeadings: SubjectFramework[] | null,
    individualHeading: SubjectFramework[] | null,
    individualSubHeading: { subjectID: number, headingCode: string, subHeadingCode: string } | null
}

const initialState: SyllabusUtilityStates = {
    allHeadings: null, individualHeading: null, individualSubHeading: null,
}

const syllabusUtilitySlice = createSlice({
    initialState,
    name: "syllabusUtilitySlice",
    reducers: {
        subjectHeadingList: (state, action) => {
            state.allHeadings = action.payload
        },
        subjectIndividualHeading: (state, action) => {
            state.individualHeading = action.payload
        },
        headingIndiviualSubHeading: (state, action) => {
            state.individualSubHeading = action.payload
        },
        emptySyllabusUtility: () => initialState,
        emptyIndividualHeading: (state) => { state.individualHeading = null },
        emptyIndividualSubheading: (state) => { state.individualSubHeading = null },
    }
})

export const {
    subjectHeadingList, subjectIndividualHeading, headingIndiviualSubHeading,
    emptySyllabusUtility, emptyIndividualHeading, emptyIndividualSubheading,
} = syllabusUtilitySlice.actions;

export default syllabusUtilitySlice.reducer;