import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { allVacancies, vacancyStructure } from './interface'

import {
    createVacancyAsync, getAllVacanciesAsync,
    getVacancyByIDAsync, updateVacancyAsync,
    vacancyCurrentStatusUpdateAsync, deleteVacancyAsync
} from './vacanciesAsync'

interface vacanciesSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    createVacancy: { code: number, message: string, data: any } | null
    getAllVacancies: allVacancies | null
    getVacancyByID: vacancyStructure | null
    updateVacancy: { code: number, message: string, data: any } | null
    vacancyCurrentStatus: { code: number, message: string } | null
    deleteVacancy: { code: number, message: string } | null
}

const initialState: vacanciesSliceStates = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    createVacancy: null,
    getAllVacancies: null,
    getVacancyByID: null,
    updateVacancy: null,
    vacancyCurrentStatus: null,
    deleteVacancy: null
}

const vacanciesSlice = createSlice({
    name: "vacanciesSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(createVacancyAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(createVacancyAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createVacancy = action.payload
        })
        builder.addMatcher(isAnyOf(createVacancyAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getAllVacanciesAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllVacanciesAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllVacancies = action.payload
        })
        builder.addMatcher(isAnyOf(getAllVacanciesAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getVacancyByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getVacancyByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getVacancyByID = action.payload?.data
        })
        builder.addMatcher(isAnyOf(getVacancyByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(updateVacancyAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(updateVacancyAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateVacancy = action.payload
        })
        builder.addMatcher(isAnyOf(updateVacancyAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(vacancyCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addMatcher(isAnyOf(vacancyCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.vacancyCurrentStatus = action.payload
        })
        builder.addMatcher(isAnyOf(vacancyCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addMatcher(isAnyOf(deleteVacancyAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addMatcher(isAnyOf(deleteVacancyAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteVacancy = action.payload
        })
        builder.addMatcher(isAnyOf(deleteVacancyAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
    },
    reducers: {
        emptyVacancies: () => initialState,
        emptyDeleteVacancies: (state, action) => { state.deleteVacancy = null },
        emptyVacanciesCurrentStatus: (state, action) => { state.vacancyCurrentStatus = null }
    }
})

export const { emptyVacancies, emptyDeleteVacancies, emptyVacanciesCurrentStatus } = vacanciesSlice.actions;
export default vacanciesSlice.reducer;