import { teal, grey } from '@mui/material/colors'
import { Components } from '@mui/material/styles'

const getMuiBottomNavigation = (mode: 'light' | 'dark'): Components['MuiBottomNavigation'] => ({
    styleOverrides: {
        root: {
            backgroundColor: mode === "dark" ? teal[700] : teal[300],
            color: mode === "dark" ? grey[300] : grey[800],
            borderRadius: "2px",
            position: "fixed",
            bottom: "0px",
            height: "40px",
            width: "100%",
        }
    }
});

export default getMuiBottomNavigation;
