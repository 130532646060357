import React, { useState, useMemo } from 'react'
import { isJson } from '../redux/axiosClient/IsJson'

import WebRoutes from './web/WebRoutes'
import UserRoutes from './user/UserRoutes'
import SuperAdminRoutes from './superAdmin/SuperAdminRoutes'


function Routes() {
    const [panel, setPanel] = useState<string>("")

    useMemo(() => {
        const userDetails = localStorage.getItem("userDetails")
        const userType = userDetails && isJson(userDetails) ? JSON.parse(userDetails)?.userType : "";

        const panels: Record<string, string> = {
            "user": "user",
            "admin": "admin",
            "superAdmin": "superAdmin",
        }

        setPanel(panels[userType] || "")
    }, [localStorage.getItem("userDetails")])

    const routes = useMemo(() => {
        return panel === "superAdmin" ? SuperAdminRoutes : panel === "user" ? UserRoutes : WebRoutes;
    }, [panel])


    return { routes }
}

export default Routes;