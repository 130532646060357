import { createSlice } from "@reduxjs/toolkit";
import { authLoginWithGoogle } from './authAsync';

interface authLoginWithGoogleState {
    loading: boolean
    responseData: { code: number | null, message: string, token: string }
}

const initialState: authLoginWithGoogleState = {
    loading: false,
    responseData: { code: null, message: "", token: "" }
}

const authLoginWithGoogleSlice = createSlice({
    name: "authLoginWithGoogleSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((authLoginWithGoogle.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((authLoginWithGoogle.fulfilled), (state, action) => {
            state.loading = false
            state.responseData = action.payload
            const authToken = { token: action.payload?.token }
            localStorage.setItem("auth", JSON.stringify(authToken))
        })
        builder.addCase((authLoginWithGoogle.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: { emptyAuthLoginWithGoogleState: () => initialState },
})

export const { emptyAuthLoginWithGoogleState } = authLoginWithGoogleSlice.actions;
export default authLoginWithGoogleSlice.reducer;