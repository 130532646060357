import { createSlice } from '@reduxjs/toolkit'
import { questionStructure } from '../question/interface'
import { createQuizAsync, getAllQuizAsync } from './quizAsync'

interface quizStates {
    loading: boolean
    createQuiz: { code: number, message: string } | null
    allQuiz: questionStructure[] | null
}

const initialState: quizStates = {
    loading: false,
    createQuiz: null,
    allQuiz: null
}

const quizSlice = createSlice({
    name: "quizSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((createQuizAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((createQuizAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createQuiz = action.payload
        })
        builder.addCase((createQuizAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((getAllQuizAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getAllQuizAsync.fulfilled), (state, action) => {
            state.loading = false
            state.allQuiz = action.payload?.data?.questions
        })
        builder.addCase((getAllQuizAsync.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: {
        emptyQuiz: () => initialState
    }
})

export const { emptyQuiz } = quizSlice.actions;
export default quizSlice.reducer;