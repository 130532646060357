import { themeBreakPoints } from './index'
import { Components, Theme } from '@mui/material/styles'

export const getMuiDialog = (breakpoints: themeBreakPoints): Components['MuiDialog'] => ({
    styleOverrides: {
        paper: {
            margin: 'auto',
            // width: '100%',
            // maxWidth: breakpoints.values.lg,
        }
    }
})

export const getMuiDialogTitle = (mode: 'light' | 'dark'): Components['MuiDialogTitle'] => ({
    styleOverrides: {
        root: (props) => {
            const { theme } = props as { theme: Theme };
            return {
                backgroundColor: mode === "light" ? theme.palette.primary.light : theme.palette.primary.dark,
            }
        }
    }
})

export const getMuiDialogContent = (mode: 'light' | 'dark'): Components['MuiDialogContent'] => ({
    styleOverrides: {
        root: (props) => {
            const { theme } = props as { theme: Theme };
            return {
                "::-webkit-scrollbar": { width: "7px", height: "7px" },
                "::-webkit-scrollbar-track": { background: "transparent" },
                "::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? theme.palette.primary.light : theme.palette.primary.dark,
                    borderRadius: "20px",
                }
            }
        }
    }
})

export const getMuiDialogContentText = (mode: 'light' | 'dark'): Components['MuiDialogContentText'] => ({
    styleOverrides: {
        root: {
            color: mode === 'light' ? 'inherit' : "",
        }
    }
})