import React, { Fragment, useEffect } from 'react'

import { useTheme, Theme } from '@mui/material/styles'

// Logos :::
import pmLogoDark from "../../asset/logos/pmLogoDark.png"
import pmLogoLight from "../../asset/logos/pmLogoLight.png"

interface siteLogoProps { sx?: React.CSSProperties }

export const SiteLogo = ({ sx }: siteLogoProps) => {
    const theme: Theme = useTheme()

    return (
        <Fragment>
            <img
                src={theme.palette.mode === 'dark' ? pmLogoDark : pmLogoLight}
                alt="Logo" style={{ ...sx }}
            />
        </Fragment>
    )
};

export const SiteFavicon = () => {
    const theme: Theme = useTheme()

    useEffect(() => {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        if (favicon) {
            favicon.href = theme.palette.mode === 'dark' ? pmLogoDark : pmLogoLight;
        }
    }, [theme.palette.mode]);

    return (<Fragment></Fragment>)
};
