import { Components } from '@mui/material/styles'

export const MuiFormLabel: Components['MuiFormLabel'] = {
    styleOverrides: {
        root: { fontFamily: 'Poppins' }
    }
};

export const MuiInputLabel: Components['MuiInputLabel'] = {
    styleOverrides: {
        root: { fontFamily: 'Poppins' }
    }
};

