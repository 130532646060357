import { createSlice } from '@reduxjs/toolkit'
import { testPaperStructure } from './interfaces'

import {
    createTestPaperAsync, deleteTestPaperAsync, getAllTestPapersAsync,
    getTestPaperByIDAsync, testPaperStatusUpdateAsync, updateTestPaperAsync
} from './testPaperAsync'

interface TestPaperSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    updateLoading: boolean
    createTestPaper: { code: number, message: string, data: testPaperStructure } | null
    allTestPapers: any
    testPaperByID: any
    updateTestPaper: { code: number, message: string } | null
    deleteTestPaper: { code: number, message: string } | null
}

const initialState: TestPaperSliceStates = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    updateLoading: false,
    createTestPaper: null,
    allTestPapers: null,
    testPaperByID: null,
    updateTestPaper: null,
    deleteTestPaper: null,
}

const testPaperSlice = createSlice({
    initialState,
    name: "testPaperSlice",
    extraReducers: (builder) => {
        builder.addCase((createTestPaperAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((createTestPaperAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createTestPaper = action.payload
        })
        builder.addCase((createTestPaperAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((getAllTestPapersAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getAllTestPapersAsync.fulfilled), (state, action) => {
            state.loading = false
            state.allTestPapers = action.payload
        })
        builder.addCase((getAllTestPapersAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((getTestPaperByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getTestPaperByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.testPaperByID = action.payload
        })
        builder.addCase((getTestPaperByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addCase((updateTestPaperAsync.pending), (state, action) => {
            state.updateLoading = true
        })
        builder.addCase((updateTestPaperAsync.fulfilled), (state, action) => {
            state.updateLoading = false
            state.updateTestPaper = action.payload
        })
        builder.addCase((updateTestPaperAsync.rejected), (state, action) => {
            state.updateLoading = false
        })
        builder.addCase((deleteTestPaperAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addCase((deleteTestPaperAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteTestPaper = action.payload
        })
        builder.addCase((deleteTestPaperAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
    },
    reducers: {
        emptyTestPaper: () => initialState,
        // emptySyllabusCurrentStatus: (state, action) => { state.syllabusCurrentStatus = null }
    }
})

export const { emptyTestPaper } = testPaperSlice.actions;
export default testPaperSlice.reducer;
