import React, { createContext, useContext, FC, PropsWithChildren } from "react"
import { createTheme, Theme, PaletteMode } from '@mui/material'
import { useCurrentThemeMode } from "./controller/Mode"

interface themeContext {
    theme: Theme,
    mode: PaletteMode,
    changeMode: () => void
}

// <----- Context Creation ------>
export const ThemeContext = createContext<themeContext>({
    mode: "light",
    changeMode: () => { },
    theme: createTheme(),
})

// <----- Wrapper/Provider Creation ------>
export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const { theme, mode, changeMode } = useCurrentThemeMode()
    const value = { mode, changeMode, theme }
    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    )
}

// <----- Using Context ------>
export const useThemeContext = () => {
    return useContext(ThemeContext)
}

