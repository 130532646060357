import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

interface applyParams { search?: string | null, status?: "active" | "inactive", page?: number, limit?: number }

// Create Syllabus:::
export const createSyllabusAsync = createAsyncThunk(
    "createSyllabus/syllabus", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/syllabus", payload, toolkit)
    }
)

// Get All Syllabus:::
export const getAllSyllabusAsync = createAsyncThunk(
    "getAllSyllabus/syllabus", async (params: applyParams, toolkit) => {
        return await AxiosClient("GET", "/syllabus", [], toolkit, params)
    }
);

// Get All Syllabus Sub-Heading Wise:::
export const getAllSyllabusBySubHeadingsAsync = createAsyncThunk(
    "getAllSyllabusBySubHeading/syllabus/all/subheading", async (params: applyParams, toolkit) => {
        return await AxiosClient("GET", "/syllabus/all/subheading", [], toolkit, params)
    }
);

// Get Syllabus By ID:::
export const getSyllabusByIDAsync = createAsyncThunk(
    "getSyllabusByID/syllabus", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/syllabus/${payload.id}`, [], toolkit)
    }
)

// Update Syllabus:::
export const updateSyllabusAsync = createAsyncThunk(
    "updateSyllabus/syllabus", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/syllabus/${payload.id}`, payload.formData, toolkit)
    }
)

// Delete Syllabus:::
export const deleteSyllabusAsync = createAsyncThunk(
    "deleteSyllabus/syllabus", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/syllabus/${payload.id}`, [], toolkit)
    }
)

// Active/Inactive Status:::
export const syllabusCurrentStatusUpdateAsync = createAsyncThunk(
    "syllabusCurrentStatus/syllabus/status", async (payload: { id: number | null, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/syllabus/update/status", payload, toolkit)
    }
)

// Get Syllabus By Exam ID:::
interface examParams { "exam_id[]": number[], status: "active" | "inactive", }
export const getSyllabusByExamIDAsync = createAsyncThunk(
    "getSyllabusByExamID/syllabus", async (params: examParams, toolkit) => {
        return await AxiosClient("GET", "/syllabus", [], toolkit, params)
    }
);

// Get Headings & Sub-Headings By Multiple Subject ID :::
interface multiSubjectParams { "subject_id[]": number[], status: "active" | "inactive", }
export const getHeadingsBySubjectIDAsync = createAsyncThunk(
    "getHeadingsBySubjectID/syllabus/all/heading", async (params: multiSubjectParams, toolkit) => {
        return await AxiosClient("GET", "/syllabus/all/heading", [], toolkit, params)
    }
);