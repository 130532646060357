import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { allCurrentAffairs, currentAffairStructure } from './interface'

import {
    createCurrentAffairAsync, getAllCurrentAffairsAsync,
    getCurrentAffairByIDAsync, updateCurrentAffairAsync,
    currentAffairCurrentStatusUpdateAsync, deleteCurrentAffairAsync
} from './currentAffairsAsync'

interface currentAffairsSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    getByIDLoading: boolean
    createCurrentAffair: { code: number, message: string, data: any } | null
    getAllCurrentAffairs: allCurrentAffairs | null
    getCurrentAffairByID: currentAffairStructure | null
    updateCurrentAffair: { code: number, message: string, data: any } | null
    currentAffairCurrentStatus: { code: number, message: string } | null
    deleteCurrentAffair: { code: number, message: string } | null
}

const initialState: currentAffairsSliceStates = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    getByIDLoading:false,
    createCurrentAffair: null,
    getAllCurrentAffairs: null,
    getCurrentAffairByID: null,
    updateCurrentAffair: null,
    currentAffairCurrentStatus: null,
    deleteCurrentAffair: null
}

const currentAffairsSlice = createSlice({
    name: "currentAffairsSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(createCurrentAffairAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(createCurrentAffairAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createCurrentAffair = action.payload
        })
        builder.addMatcher(isAnyOf(createCurrentAffairAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getAllCurrentAffairsAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllCurrentAffairsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllCurrentAffairs = action.payload
        })
        builder.addMatcher(isAnyOf(getAllCurrentAffairsAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getCurrentAffairByIDAsync.pending), (state, action) => {
            state.getByIDLoading = true
        })
        builder.addMatcher(isAnyOf(getCurrentAffairByIDAsync.fulfilled), (state, action) => {
            state.getByIDLoading = false
            state.getCurrentAffairByID = action.payload?.data
        })
        builder.addMatcher(isAnyOf(getCurrentAffairByIDAsync.rejected), (state, action) => {
            state.getByIDLoading = false
        })
        builder.addMatcher(isAnyOf(updateCurrentAffairAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(updateCurrentAffairAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateCurrentAffair = action.payload
        })
        builder.addMatcher(isAnyOf(updateCurrentAffairAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(currentAffairCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addMatcher(isAnyOf(currentAffairCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.currentAffairCurrentStatus = action.payload
        })
        builder.addMatcher(isAnyOf(currentAffairCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addMatcher(isAnyOf(deleteCurrentAffairAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addMatcher(isAnyOf(deleteCurrentAffairAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteCurrentAffair = action.payload
        })
        builder.addMatcher(isAnyOf(deleteCurrentAffairAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
    },
    reducers: {
        emptyCurrentAffairs: () => initialState,
        emptyDeleteCurrentAffair: (state, action) => { state.deleteCurrentAffair = null },
        emptyCurrentAffairCurrentStatus: (state, action) => { state.currentAffairCurrentStatus = null }
    }
})

export const { emptyCurrentAffairs, emptyCurrentAffairCurrentStatus, emptyDeleteCurrentAffair } = currentAffairsSlice.actions;
export default currentAffairsSlice.reducer;