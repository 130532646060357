import { createSlice } from "@reduxjs/toolkit"

interface initialStateDataType {
    status: number | null, statusText: string
}

const initialState: initialStateDataType = {
    status: null, statusText: ""
}

const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        errorMessage: (state, action) => {
            state.status = action.payload?.data?.code
            state.statusText = action.payload?.data?.message
        },
        emptyErrorMessage: () => initialState
    }
});


export const { errorMessage, emptyErrorMessage } = errorSlice.actions;
export default errorSlice.reducer;

