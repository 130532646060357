import React, { Fragment, useCallback } from 'react'
import { FormikProps } from 'formik'

import { Autocomplete, TextField } from '@mui/material'
import Loader from '../../components/Loader/Loader'

interface dropdownOption {
    id: number;
    label: string;
    value: string | number;
}
interface multiSelectProps<FormValues> {
    label: string
    loading: boolean
    multiple?: boolean
    disabled?: boolean
    placeholder?: string
    name: keyof FormValues
    dropdown: dropdownOption[]
    Formik: FormikProps<FormValues>
}


function MultiSelect<FormValues>(props: multiSelectProps<FormValues>) {
    const { name, label, Formik, dropdown, loading, disabled, placeholder, multiple } = props

    const isOptionEqualToValue = useCallback(
        (option: dropdownOption, value: dropdownOption) => option.value === value.value,
        []
    );
    return (
        <Fragment>
            <Autocomplete
                multiple={multiple || true}
                options={dropdown}
                filterSelectedOptions
                value={Formik.values[name] as dropdownOption[] || []}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={(option) => option.label || ""}
                onChange={(event, newValue) => {
                    Formik.setFieldValue(name as string, newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name={name as string}
                        placeholder={placeholder}
                        error={Boolean(Formik.touched[name] && Formik.errors[name])}
                        label={loading ? (<Loader padding="0 0" size={20} />) : label}
                    />
                )}
                fullWidth
                loading={loading}
                disabled={disabled}
                loadingText={<Loader padding="0 0" size={20} />}
            />
        </Fragment>
    )
}

export default MultiSelect;

