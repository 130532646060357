import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

// Create Exam:::
export const createExamAsync = createAsyncThunk(
    "createExam/exam", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/exams", payload, toolkit)
    }
)

// Get All Exam:::
interface examParams { search?: string | null, status?: "active" | "inactive", page?: number, limit?: number }
export const getAllExamAsync = createAsyncThunk(
    "getAllExam/exam", async (params: examParams, toolkit) => {
        return await AxiosClient("GET", "/exams", [], toolkit, params)
    }
);

// Get Exam By ID:::
export const getExamByIDAsync = createAsyncThunk(
    "getExamByID/exam", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/exams/${payload.id}`, [], toolkit)
    }
)

// Update Exam:::
export const updateExamAsync = createAsyncThunk(
    "updateExam/exam", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/exams/${payload.id}`, payload.formData, toolkit)
    }
)

// Active/Inactive Status:::
export const examCurrentStatusUpdateAsync = createAsyncThunk(
    "examCurrentStatus/exams/status", async (payload: { id: number, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/exams/update/status", payload, toolkit)
    }
)

// Exam Web Visibility :::
export const examWebVisibilityAsync = createAsyncThunk(
    "examinerWebVisibility/show-hide-exam", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/show-hide-exam", payload, toolkit)
    }
)

// Delete Exam:::
export const deleteExamAsync = createAsyncThunk(
    "deleteExam/exams", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/exams/${payload.id}`, [], toolkit)
    }
)

// Get Exam By Examiner ID :::
interface examinerParams { "examiner_id[]": number[], status: "active" | "inactive", }
export const getExamByExaminerIDAsync = createAsyncThunk(
    "getExamByExaminerID/exam", async (params: examinerParams, toolkit) => {
        return await AxiosClient("GET", "/exams", [], toolkit, params)
    }
)