import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './styles/webPanelFooter.module.css'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone'
import WifiCalling3TwoToneIcon from '@mui/icons-material/WifiCalling3TwoTone'

import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TelegramIcon from '@mui/icons-material/Telegram'

import { SiteLogo } from '../../../theme/controller/Logo'
export default function WebPanelFooter() {
    const navigate = useNavigate()
    return (
        <Fragment>
            <Container sx={{ maxWidth: { xs: "100%", lg: '1300px' } }}
                className={classes.footerContainer}
            >
                <Stack className={classes.contentContainer}>

                    <Box className={classes.leftBox}>
                        <Box className={classes.textContent}>
                            <AlternateEmailIcon className={classes.leftBoxImage} />
                            <Typography>
                                <a className={classes.navigation} href="mailto:mantrapariksha34@gmail.com">mantrapariksha34@gmail.com</a>
                            </Typography>
                        </Box>

                        <Box className={classes.textContent}>
                            <WifiCalling3TwoToneIcon className={classes.leftBoxImage} />
                            <Typography>
                                <a className={classes.navigation} href="tel:+919329502435">+91 93295 02435</a>
                            </Typography>

                            {/* Alternative Way ::: */}
                            {/* <Typography>
                                <Link href="tel:+1234567890" color="primary" underline="hover">+1 (234) 567-890 </Link>
                            </Typography> */}
                        </Box>

                        <Box className={classes.textContent}>
                            <LocationOnTwoToneIcon className={classes.leftBoxImage} />
                            <Typography>Santoshi Nagar, Raipur, Chhattisgarh</Typography>
                        </Box>

                    </Box>
                    <Box className={classes.rightBox}>
                        <Box className={classes.socialMediaContainer}>
                            <FacebookIcon sx={{ color: "#0866FF" }} className={classes.socialMedia} />
                            <LinkedInIcon sx={{ color: "#0A66C2" }} className={classes.socialMedia} />
                            <WhatsAppIcon sx={{ color: "#25D366" }} className={classes.socialMedia} />
                            <TelegramIcon sx={{ color: "#3390EC" }} className={classes.socialMedia} />
                            <YouTubeIcon sx={{ color: "#FF0000" }} className={classes.socialMedia} />
                        </Box>

                        <Box>
                            <Typography onClick={() => navigate("/terms-conditions")} className={classes.TAC}>
                                Privacy Policy | Terms & Conditions
                            </Typography>
                        </Box>
                    </Box>
                </Stack>

                <Stack className={classes.brandContainer}>
                    <Box className={classes.logoContent}>
                        <SiteLogo sx={{ height: "auto", width: "100px", borderRadius: "25px", marginLeft: "-20px" }} />
                        <Typography className={classes.logoText}>Pariksha Mantra</Typography>
                    </Box>

                    <Box>
                        <Typography className={classes.allRightsText}>&copy; Pariksha Mantra • All Rights Received</Typography>
                    </Box>
                </Stack>
            </Container>
        </Fragment>
    )
};