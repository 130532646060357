import ReactDOM from 'react-dom/client'
import React from 'react'
import App from './App'
import './index.css'

import { Provider } from 'react-redux'
import { store } from './redux/store/store'

import { HelmetProvider } from 'react-helmet-async'
import { SnackAlertProvider } from './components/snackAlert/SnackAlert'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ThemeContextProvider } from './theme/ThemeContextProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET || "";


root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <ThemeContextProvider>
      <Provider store={store}>
        <SnackAlertProvider>
          <GoogleOAuthProvider clientId={clientId}>
            <App />
          </GoogleOAuthProvider>
        </SnackAlertProvider>
      </Provider>
    </ThemeContextProvider>
  </HelmetProvider>
  // </React.StrictMode>
);

