import React, { Fragment, useState } from 'react'
import classes from './components/root.module.css'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/system'

import { menuItems } from './components/userLayoutMenu'
import SideNavBar from '../../navigation/sideNavBar/SideNavBar'
import ControlPanelFooter from '../footer/controlPanel/ControlPanelFooter'

function UserLayout() {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  return (
    <Fragment>
      <SideNavBar {...{ isOpen, setIsOpen, menuItems }} />
      <Box className={isOpen ? classes.show : classes.hide}>
        <Outlet />
        <ControlPanelFooter />
      </Box>
    </Fragment>
  )
}

export default UserLayout