// MUI Icon Import
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';

export const menuList = [
    { id: 1, name: 'Home', path: '/', component: "page", subList: [], icon: HomeIcon },
    {
        id: 2, name: 'Outlets', path: '', component: "page", subList: [
            { id: 3, name: "Current Affairs", path: '/currentAffairs' },
            { id: 2, name: "Vacancies", path: '/vacancies' },
            { id: 1, name: "Exams", path: '/exams' },
        ], icon: ArticleIcon
    },
    {
        // id: 3, name: 'Login', path: '/login', component: "modal", subList: [], icon: PersonIcon
        id: 3, name: 'Login', path: '/auth', component: "page", subList: [], icon: PersonIcon
    },
];