import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from "../../../../axiosClient/AxiosClient"

interface statusUpdate { id: number, status: "active" | "inactive" }
interface applyParams { search?: string | null, status?: "active" | "inactive", page?: number, limit?: number }

export const createTestPaperAsync = createAsyncThunk(
    "create/question-paper", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/question-paper", payload, toolkit)
    }
)

export const getAllTestPapersAsync = createAsyncThunk(
    "read/question-paper", async (params: applyParams, toolkit) => {
        return await AxiosClient("GET", "/question-paper", [], toolkit, params)
    }
)

export const getTestPaperByIDAsync = createAsyncThunk(
    "readID/question-paper", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/question-paper/${payload.id}`, [], toolkit)
    }
)

export const updateTestPaperAsync = createAsyncThunk(
    "update/question-paper", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/question-paper/${payload.id}`, payload.formData, toolkit)
    }
)

export const deleteTestPaperAsync = createAsyncThunk(
    "delete/question-paper", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/question-paper/${payload.id}`, [], toolkit)
    }
)

export const testPaperStatusUpdateAsync = createAsyncThunk(
    "statusUpdate/question-paper/status", async (payload: statusUpdate, toolkit) => {
        return await AxiosClient("POST", "/question-paper/update/status", payload, toolkit)
    }
)