import { Components } from '@mui/material/styles'

const MuiButton: Components['MuiButton'] = {
    styleOverrides: {
        root: {
            borderRadius: "10px",
            fontFamily: "Poppins",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "capitalize",
        },
        outlined: {
            border: '1px solid',
        },
    },
    defaultProps: {
        size: "small",
    },
};

export default MuiButton;
