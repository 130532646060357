import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { FormikProps } from 'formik'
import axios from 'axios'

import Button from '@mui/material/Button'
import GoogleIcon from '@mui/icons-material/Google'

import { loginState } from './utils'
import { useSnackAlert } from '../../components/snackAlert/SnackAlert'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google'

// API Section :::
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
import { authLoginWithGoogle, saveCurrentUserDetails } from '../../redux/slice/auth/authAsync'

interface googleLoginProps {
    Formik: FormikProps<loginState>
}

function GoogleLogin({ Formik }: googleLoginProps) {
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState<boolean>(false)

    const { setSeverity, setOpen, setMessage } = useSnackAlert()
    const { responseData } = useAppSelector((state) => state.googleLogin)

    const errorToaster = useCallback((type: 'accountType' | 'onSuccessBlockError' | 'onErrorBlock') => {
        const messages: Record<string, string> = {
            onErrorBlock: "Something Went Wrong While Authenticating With Google",
            accountType: "Please Select Your Account Type 'Student' Or 'Institute'",
            onSuccessBlockError: "Something Went Wrong While Authenticating With Google",
        }
        setOpen(true)
        setSeverity('error')
        setMessage(messages[type] || '')
    }, [setOpen, setSeverity, setMessage])

    const login = useGoogleLogin({
        onSuccess: async (credentialResponse) => {
            try {
                setLoading(true)
                const response = await axios.post(`${process.env.REACT_APP_GOOGLE_USER_AUTH}`, {},
                    {
                        headers: {
                            'Authorization': `Bearer ${credentialResponse.access_token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                )

                const formData = new FormData()
                formData.append('sub', response?.data?.sub)
                formData.append('name', response?.data?.name)
                formData.append('email', response?.data?.email)
                // formData.append('type', Formik.values.accountType)
                formData.append('picture', response?.data?.picture)
                formData.append('account_type', Formik.values.accountType)
                formData.append('user_type', Formik.values.accountType === 'institute' ? 'admin' : 'user')

                await dispatch(authLoginWithGoogle(formData))
            }
            catch (error) { errorToaster('onSuccessBlockError') }
            finally { setLoading(false) }
        },

        onError: () => { errorToaster('onErrorBlock') }
    })

    const handleGoogleLogin = useCallback(() => {
        Formik.values.accountType === '' ? errorToaster('accountType') : login()
    }, [Formik.values.accountType])

    useMemo(() => {
        if (responseData.code === 200) {
            dispatch(saveCurrentUserDetails())
            setSeverity('success')
            setOpen(true)
            setMessage(responseData.message)
        }
    }, [responseData])

    return (
        <Fragment>
            <Button variant="outlined" sx={{ px: 3, py: 0.5 }} onClick={handleGoogleLogin}
                startIcon={<GoogleIcon sx={{ position: "relative", bottom: 1 }} />}
            >
                Login With Google
            </Button>
        </Fragment>
    )
}

export default GoogleLogin; 
