import React from 'react'
import { Stack } from '@mui/material'

type styleWrapper = {
    children: React.ReactNode
}
function StyleWrapper({ children }: styleWrapper) {
    return (
        <Stack sx={{ fontFamily: "Poppins" }}>
            {children}
        </Stack>
    )
}

export default StyleWrapper;