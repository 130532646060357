import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

interface radioInputProps<FormValues> {
    rowWise: boolean
    disabled?: boolean
    mainHeading?: string
    name: keyof FormValues
    size?: "small" | "medium"
    Formik: FormikProps<FormValues>
    radioGroups: { id: number, label: string, value: string }[]
}

function RadioInput<FormValues>(props: radioInputProps<FormValues>) {
    const { mainHeading, rowWise, name, radioGroups, Formik, size, disabled } = props
    return (
        <Fragment>
            <FormControl>
                {mainHeading ? (
                    <FormLabel id="demo-row-radio-buttons-group-label">{mainHeading}</FormLabel>
                ) : null}

                <RadioGroup
                    row={rowWise}
                    aria-labelledby="radio-buttons-group-label"
                    name={name as string}
                    value={Formik.values[name]}
                    onChange={Formik.handleChange}
                    sx={{ zIndex: 1 }}
                >
                    {
                        radioGroups?.length ? radioGroups.map((ele) => {
                            return (
                                <FormControlLabel
                                    key={ele.id}
                                    value={ele.value}
                                    label={ele.label}
                                    control={<Radio size={size || "small"} />}
                                    disabled={disabled}
                                />
                            )
                        })
                            : null
                    }
                </RadioGroup>
            </FormControl>
        </Fragment>
    )
}

export default RadioInput;