import React, { Fragment } from 'react'
import { FormikProps } from 'formik'
import { FileWithPath } from 'react-dropzone'

import { ImageUpload } from '../index'

interface imageUploadGearProps<initialState> {
    label: string
    name: keyof initialState
    Formik: FormikProps<initialState>
}

export default function ImageUploadGear<initialState>(props: imageUploadGearProps<initialState>) {
    const { Formik, name, label } = props

    const handleDrop = (acceptedFiles: FileWithPath[]) => {
        const file = acceptedFiles[0];
        const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        })
        if (file) {
            Formik.setFieldValue(name as string, [newFile]);
        }
    }

    const files = Formik.values[name] as { preview: string, [key: string]: any }[];

    return (
        <Fragment>
            <ImageUpload
                label={label}
                onDrop={handleDrop}
                name={name as string}
                file={files[0] || []}
                accept={{ "image/*": [] }}
                error={Boolean(Formik.touched[name] && Formik.errors[name])}
            />
        </Fragment>
    )
};