import React, { Fragment } from 'react'
import { FormikProps } from 'formik'


import dayjs, { Dayjs } from 'dayjs'
import InputAdornment from '@mui/material/InputAdornment'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import Loader from '../../components/Loader/Loader'

type calendarType = "month" | "year"

interface dateSelectorProps<FormValues> {
    label: string
    minDate?: string
    maxDate?: string
    loading?: boolean
    disabled?: boolean
    disablePast?: boolean
    isEditModeOn: boolean
    name: keyof FormValues
    sx?: React.CSSProperties
    executionFun2: () => void
    executionFun1?: () => void
    calendarView?: calendarType[]
    Formik: FormikProps<FormValues>
    openFrom?: "day" | "month" | "year"
    icon1: React.ElementType<SvgIconProps>
    icon2: React.ElementType<SvgIconProps>
    setEditModeOn: (status: boolean) => void
}

export default function DoubleButtonDateSelector<FormValues>(props: dateSelectorProps<FormValues>) {
    const {
        name, label, Formik, loading, disabled, openFrom, calendarView, minDate, maxDate, disablePast,
        sx, isEditModeOn, setEditModeOn, icon1: MUIIcon1, icon2: MUIIcon2, executionFun1, executionFun2,
    } = props

    const handleDateChange = (date: Dayjs | null) => {
        Formik.setFieldValue(name as string, date)
    }

    const handleEditMode = () => {
        setEditModeOn(!isEditModeOn);
    }

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    sx={{ ...sx }}
                    name={name as string}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                    value={Formik.values[name] as unknown as Dayjs | null}
                    onChange={handleDateChange}
                    format="DD MMM YYYY"
                    openTo={openFrom}
                    views={calendarView}
                    disablePast={disablePast}
                    disabled={disabled || !isEditModeOn}
                    minDate={dayjs(minDate, "DD/MM/YYYY")}
                    maxDate={dayjs(maxDate, "DD/MM/YYYY")}

                    slotProps={{
                        textField: () => ({
                            size: 'small',
                            focused: false,
                            fullWidth: true,
                            error: Boolean(Formik.touched[name] && Formik.errors[name]),
                            InputProps: {
                                sx: { svg: { color: 'primary.main' } },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            !isEditModeOn ? <MUIIcon1 color='primary' cursor='pointer' onClick={handleEditMode} />
                                                : <MUIIcon2 color='primary' cursor='pointer' onClick={() => executionFun2()} />
                                        }
                                    </InputAdornment>
                                )
                            },
                        }),
                        openPickerButton: { color: 'primary' },
                        inputAdornment: { position: loading ? 'end' : 'start' }, // To show loader while loading.
                    }}
                />
            </LocalizationProvider>
        </Fragment>
    )
};
