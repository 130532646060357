import React, { Fragment } from 'react'
import { FieldArray, FormikProps, FieldArrayRenderProps } from 'formik'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import Loader from '../../components/Loader/Loader'

interface singleDynamicField<FormValues> {
    name: string
    label: string
    Formik: FormikProps<FormValues>
}

function SingleDynamicField<FormValues extends { features: { merits: string }[] }>(props: singleDynamicField<FormValues>) {
    const { Formik, name, label } = props

    return (
        <Fragment>
            <Stack>
                <FieldArray name={name}>
                    {(fieldArrayProps: FieldArrayRenderProps) => {
                        const { form } = fieldArrayProps
                        const { values } = form
                        return (
                            <Fragment>
                                {
                                    Formik.values?.features?.length ? Formik.values.features.map((ele: any, index: number) => {
                                        return (
                                            <Grid item xs={12} key={index} sx={{ mb: 1.5 }}>
                                                <FormControl fullWidth disabled={false}>
                                                    <TextField fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    {
                                                                        Formik.values?.features?.length !== 0 && (
                                                                            <Fragment>
                                                                                {(index !== 0 || Formik.values?.features?.length > 1) ? (
                                                                                    <DeleteOutlinedIcon fontSize='small'
                                                                                        onClick={() => fieldArrayProps.remove(index)}
                                                                                        sx={{ cursor: 'pointer', "&:hover": { color: (theme) => theme.palette.error.main } }}
                                                                                    />
                                                                                ) : null}
                                                                                {Formik.values?.features?.length - 1 === index && (
                                                                                    <AddOutlinedIcon fontSize='small'
                                                                                        onClick={() => fieldArrayProps.push({ merits: "" })}
                                                                                        sx={{ cursor: 'pointer', "&:hover": { color: (theme) => theme.palette.primary.main } }}
                                                                                    />
                                                                                )}
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                </InputAdornment>)
                                                        }}
                                                        label={false ? (<Loader padding="0 0" size={20} />) : label}
                                                        name={`features.${index}.merits`}
                                                        placeholder={`Enter ${label} ${index + 1}`}
                                                        value={Formik.values.features[index]?.merits}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            Formik.setFieldValue(`features.${index}.merits`, event.target.value)
                                                        }}

                                                        // error={Boolean(Formik.touched?.features?.[index]?.merits && Formik.errors.features?.[index])}
                                                        error={Boolean(Formik.touched?.features && Formik.errors.features?.[index])}

                                                    />
                                                </FormControl>
                                            </Grid>
                                        )
                                    })

                                        : null
                                }
                            </Fragment>
                        )
                    }}
                </FieldArray>
            </Stack>
        </Fragment>
    )
}

export default SingleDynamicField
