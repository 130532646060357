import { createBrowserRouter } from 'react-router-dom';

import UserLayout from '../../layout/root/UserLayout'

import Suspense from '../zSuspense/Suspense'
import { Dashboard, Questions, Exams, Vacancies, Others, PageNotFound } from './components/routeLazyLoading'


const UserRoutes = createBrowserRouter([
    {
        path: '/',
        element: <UserLayout />,
        errorElement: <Suspense><PageNotFound /></Suspense>,
        children: [
            { path: '/', element: <Suspense><Dashboard /></Suspense> },
            { path: '/others', element: <Suspense><Others /></Suspense> },
            { path: '/exam-info', element: <Suspense><Exams /></Suspense> },
            { path: '/questions', element: <Suspense><Questions /></Suspense> },
            { path: '/vacancies', element: <Suspense><Vacancies /></Suspense> },
        ]
    }
])


export default UserRoutes;