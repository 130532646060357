// Icon Imports:::

import IsoOutlinedIcon from '@mui/icons-material/IsoOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import WebhookTwoToneIcon from '@mui/icons-material/WebhookTwoTone';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

export const menuItems = [
    { id: 1, path: "dashboard", name: "Dashboard", icon: SpaceDashboardOutlinedIcon, parent: "dashboard", subMenu: [] },
    {
        id: 2, path: "", name: "Master", icon: WebhookTwoToneIcon, parent: "/master/", subMenu: [
            { id: 1, path: "master/examiners", name: "Examiner" },
            { id: 2, path: "master/exams", name: "Exams" },
            { id: 3, path: "master/syllabus", name: "Syllabus" },
        ]
    },
    {
        id: 3, path: "", name: "Questions", icon: ArticleOutlinedIcon, parent: "/questions/", subMenu: [
            { id: 1, path: "questions/bulkUpload", name: "Bulk Upload" },
            { id: 2, path: "questions/easyUpload", name: "Easy Upload" },
            { id: 3, path: "questions/questionCollection", name: "Question Collection" },
        ]
    },
    {
        id: 4, path: "", name: "Test Series", icon: IsoOutlinedIcon, parent: "/test/", subMenu: [
            { id: 1, path: "test/testSeries", name: "Test Papers" },
            { id: 2, path: "test/createTestPaper", name: "Create Test Paper" },
        ]
    },
    {
        id: 5, path: "", name: "Web", icon: LanguageRoundedIcon, parent: "/web/", subMenu: [
            { id: 1, path: "web/quiz", name: "Quiz" },
            { id: 2, path: "web/vacancies", name: "Vacancies" },
            { id: 3, path: "web/currentAffairs", name: "Current Affairs" },
            { id: 4, path: "web/productManagement", name: "Product Management" },
        ]
    },
    {
        id: 6, path: "", name: "User", icon: PersonOutlinedIcon, parent: "/user/", subMenu: [
            { id: 1, path: "user/test", name: "Test" },
            { id: 2, path: "user/claims", name: "Claims" },
            { id: 3, path: "user/doubts", name: "Doubts" },
            { id: 4, path: "user/notifications", name: "Notifications" },
            { id: 5, path: "user/offerCoupons", name: "Offer & Coupons" },
        ]
    },
    {
        id: 7, path: "", name: "Admin", icon: SupervisorAccountOutlinedIcon, parent: "/admin/", subMenu: [
            { id: 1, path: "admin/roles", name: "Roles & Permissions" }
        ]
    },
    {
        id: 8, path: "", name: "Super Admin", icon: ManageAccountsOutlinedIcon, parent: "/controlPanel/", subMenu: [
            { id: 1, path: "controlPanel/users", name: "Users" },
            { id: 2, path: "controlPanel/payment", name: "Payment" },
        ]
    },
];