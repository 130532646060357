import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { allExams, examStructure } from './interface'

import {
    createExamAsync, getAllExamAsync, getExamByIDAsync, updateExamAsync,
    examCurrentStatusUpdateAsync, deleteExamAsync, examWebVisibilityAsync,
    getExamByExaminerIDAsync,
} from './examAsync'

interface examSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    webVisibilitLoading: boolean
    createExam: { code: number, message: string, data: any } | null
    getAllExam: allExams | null
    getExamByID: examStructure | null
    updateExam: { code: number, message: string, data: any } | null
    examCurrentStatus: { code: number, message: string } | null
    examWebVisibility: { code: number, message: string } | null
    deleteExam: { code: number, message: string } | null
    examByExaminerID: allExams | null
}

const initialState: examSliceStates = {
    loading: false,
    statusLoading: false,
    deleteLoading: false,
    webVisibilitLoading: false,
    createExam: null,
    getAllExam: null,
    getExamByID: null,
    updateExam: null,
    deleteExam: null,
    examCurrentStatus: null,
    examWebVisibility: null,
    examByExaminerID: null
}

const examSlice = createSlice({
    name: "examSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getAllExamAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllExamAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllExam = action.payload
        })
        builder.addMatcher(isAnyOf(getAllExamAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(createExamAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(createExamAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createExam = action.payload
        })
        builder.addMatcher(isAnyOf(createExamAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getExamByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getExamByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getExamByID = action.payload?.data
        })
        builder.addMatcher(isAnyOf(getExamByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(updateExamAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(updateExamAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateExam = action.payload
        })
        builder.addMatcher(isAnyOf(updateExamAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(examCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addMatcher(isAnyOf(examCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.examCurrentStatus = action.payload
        })
        builder.addMatcher(isAnyOf(examCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addMatcher(isAnyOf(examWebVisibilityAsync.pending), (state, action) => {
            state.webVisibilitLoading = true
        })
        builder.addMatcher(isAnyOf(examWebVisibilityAsync.fulfilled), (state, action) => {
            state.webVisibilitLoading = false
            state.examWebVisibility = action.payload
        })
        builder.addMatcher(isAnyOf(examWebVisibilityAsync.rejected), (state, action) => {
            state.webVisibilitLoading = false
        })
        builder.addMatcher(isAnyOf(deleteExamAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addMatcher(isAnyOf(deleteExamAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteExam = action.payload
        })
        builder.addMatcher(isAnyOf(deleteExamAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
        builder.addMatcher(isAnyOf(getExamByExaminerIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getExamByExaminerIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.examByExaminerID = action.payload
        })
        builder.addMatcher(isAnyOf(getExamByExaminerIDAsync.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: {
        emptyExam: () => initialState,
        emptyDeleteExam: (state, action) => { state.deleteExam = null },
        exptyExamCurrentStatus: (state, action) => { state.examCurrentStatus = null },
        emptyExamWebVisibility: (state, action) => { state.examWebVisibility = null }
    }
})

export const { emptyExam, emptyDeleteExam, exptyExamCurrentStatus, emptyExamWebVisibility } = examSlice.actions;
export default examSlice.reducer;