import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

// Create User :::
export const createUserAsync = createAsyncThunk(
    "createUser/users", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/users", payload, toolkit)
    }
);

// All Users :::
interface usersParams { search?: string | null, status?: "active" | "inactive", page?: number, limit?: number }
export const getAllusersAsync = createAsyncThunk(
    "getAllusers/users", async (params: usersParams, toolkit) => {
        return await AxiosClient("GET", "/users", [], toolkit, params)
    }
);

// Get User By ID :::
export const getUsersByIDAsync = createAsyncThunk(
    "getUsersByID/users", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/users/${payload.id}`, [], toolkit)
    }
);