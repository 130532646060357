import React, { Fragment, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

import { subMenu } from './interface'
import { SubMenuContainer, subMenuItemsStyles } from '../styles/sideBarStyles'

export default function SubMenu(props: { subMenu: subMenu[] }) {
    const { subMenu } = props

    const location = useLocation()
    const { pathname } = location

    const [isHovered, setIsHovered] = useState<number | null>(null)

    const handleMouseEnter = (id: number) => { setIsHovered(id) }
    const handleMouseLeave = () => { setIsHovered(null) }

    return (
        <Fragment>
            <SubMenuContainer>
                {
                    subMenu?.length ? subMenu.map((sub) => {
                        const isActive = pathname?.includes(sub.path);
                        const isCurrentlyHovered = isHovered === sub.id;
                        return (
                            <Link key={sub.id} to={sub.path} style={subMenuItemsStyles}
                                onMouseEnter={() => handleMouseEnter(sub.id)} onMouseLeave={handleMouseLeave}
                            >
                                <FiberManualRecordIcon
                                    sx={{
                                        fontSize: isActive ? '10px' : '8px',
                                        ml: isActive ? '-1px' : '',
                                        color: (isCurrentlyHovered || isActive) ? (theme) => theme.palette.primary.main : ''
                                    }} />
                                <Typography
                                    sx={{
                                        fontWeight: isActive ? 600 : 400,
                                        color: isActive ? (theme) => theme.palette.primary.main : "",
                                        '&:hover': {
                                            fontWeight: isActive ? 600 : 500,
                                            color: (theme) => theme.palette.primary.main
                                        }
                                    }}>
                                    {sub.name}
                                </Typography>
                            </Link>
                        )
                    }) : null
                }
            </SubMenuContainer>
        </Fragment>
    )
};