import React, { Fragment, useState, useRef } from 'react'

// <----- React Crop ----->
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { Crop, makeAspectCrop, centerCrop, convertToPixelCrop, PixelCrop } from 'react-image-crop'
// <----- React Crop ----->

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import setCanvasPreview from './setCanvasPreview'
import { LeftAlignedBox, CenterAlignedBox } from '../../styledComponents/styledComponents'

interface imageCropper {
    croppedImage: any
    buttonText: string
    isCircular: boolean
    setIsOpen: (status: boolean) => void
}

function ImageCropper(props: imageCropper) {
    const { croppedImage, setIsOpen, buttonText, isCircular } = props

    const imgRef = useRef<HTMLImageElement | null>(null)
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)

    const [crop, setCrop] = useState<Crop | undefined>()
    const [error, setError] = useState<string>("")
    const [imageSrc, setImageSrc] = useState<string>("")

    const [minDimension, setMinDimension] = useState<number>(150)
    // const [aspectRatio, setAspectRatio] = useState<number>(1)
    const [aspectRatio, setAspectRatio] = useState<number | undefined>()

    const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (!file) return

        const fileReader = new FileReader()
        fileReader.addEventListener("load", () => {
            const imageElement = new Image()

            // Step 01: Getting image URL & setting it.
            const imageURL = fileReader.result?.toString() || ""
            imageElement.src = imageURL

            imageElement.addEventListener("load", (event) => {
                if (error) setError("")
                const { naturalWidth, naturalHeight } = event.currentTarget as HTMLImageElement
                if (naturalWidth < minDimension || naturalHeight < minDimension) {
                    setError("Image Must Be 150 * 150 Pixels")
                    return setImageSrc("")
                }
            })
            setImageSrc(imageURL)
        })

        fileReader.readAsDataURL(file)
    }

    // This is used to show crop area when image is loaded successfully.
    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = event.currentTarget
        const cropWidthInPercent = (minDimension / width) * 100;

        const aspect = aspectRatio !== undefined ? aspectRatio : 1

        const crop = makeAspectCrop(
            { unit: "%", width: cropWidthInPercent }, aspect, width, height
        )
        const centeredCrop = centerCrop(crop, width, height)
        setCrop(centeredCrop)
    }


    return (
        <Fragment>
            <Container sx={{ mt: 1.1, minHeight: "70vh", minWidth: "70vw" }}>

                <LeftAlignedBox mb={1}>
                    <Button variant="contained" component="label" >
                        {buttonText}
                        <input
                            hidden
                            type="file"
                            accept="image/*"
                            multiple={false}
                            onChange={handleSelectFile}
                        />
                    </Button>
                </LeftAlignedBox>



                {error && <Typography color="red">{error}</Typography>}
                <CenterAlignedBox sx={{ flexDirection: "column" }}>
                    {imageSrc ? (
                        <Fragment>
                            <ReactCrop
                                crop={crop}
                                ruleOfThirds
                                keepSelection
                                aspect={aspectRatio}
                                minWidth={minDimension}
                                circularCrop={isCircular}
                                onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                            >
                                <img ref={imgRef} src={imageSrc} alt='Selected Image' style={{ maxHeight: "50vh" }}
                                    onLoad={handleImageLoad}
                                />
                            </ReactCrop>

                            <Button sx={{ mt: 2 }} onClick={async () => {
                                if (imgRef.current && previewCanvasRef.current && crop) {
                                    setCanvasPreview(
                                        imgRef.current,
                                        previewCanvasRef.current,
                                        convertToPixelCrop(
                                            crop,
                                            imgRef.current.width,
                                            imgRef.current.height
                                        ) as PixelCrop
                                    );
                                    const croppedImageData = previewCanvasRef.current.toDataURL()
                                    croppedImage(croppedImageData)
                                    setIsOpen(false)
                                }
                            }}
                            >
                                Crop Image
                            </Button>
                        </Fragment>
                    ) : null}
                </CenterAlignedBox>

                {crop && (
                    <canvas
                        ref={previewCanvasRef}
                        className="mt-4"
                        style={{
                            display: "none",
                            border: "1px solid black",
                            objectFit: "contain",
                            width: 150,
                            height: 150,
                        }}
                    />
                )}
            </Container>
        </Fragment>
    )
}

export default ImageCropper;