import { Components } from '@mui/material/styles'

export const MuiStep: Components['MuiStep'] = {
    styleOverrides: {
        root: { fontFamily: 'Poppins' },
    },

};
export const MuiStepLabel: Components['MuiStepLabel'] = {
    styleOverrides: {
        root: { fontFamily: 'Poppins' },
        label: { fontFamily: 'Poppins' },
    },

};
