import { lazy } from 'react'


// Authentication :::
export const Authentication = lazy(() => import("../../../auth/Authentication"))

// Web Components:::
export const LandingPage = lazy(() => import("../../../pages/webPanel/landingPage/LandingPage"))
export const Exams = lazy(() => import("../../../pages/webPanel/outlets/exams/Exams"))
export const Vacancies = lazy(() => import("../../../pages/webPanel/outlets/vacancies/Vacancies"))
export const CurrentAffairs = lazy(() => import("../../../pages/webPanel/outlets/currentAffairs/CurrentAffairs"))
export const TopicWiseCurrentAffair = lazy(() => import("../../../pages/webPanel/outlets/currentAffairs/TopicWiseCurrentAffair"))
// Terms And Conditions :::
export const TermsAndConditions = lazy(() => import("../../../pages/common/legislation/TermsAndConditions"))

// 404 Component:::
export const PageNotFound = lazy(() => import("../../../layout/404/PageNotFound"))