import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { questionStructure, questionInterface } from './interface'
import {
    createQuestionAsync, getAllQuestionsAsync,
    getQuestionByIDAsync, updateQuestionAsync,
    questionCurrentStatusUpdateAsync, deleteQuestionAsync
} from './questionAsync'

interface questionSliceStates {
    loading: boolean
    statusLoading: boolean
    deleteLoading: boolean
    createQuestion: { code: number, message: string } | null
    getAllQuestions: questionInterface | null
    getQuestionByID: questionStructure | null
    updateQuestion: { code: number, message: string } | null
    questionCurrentStatus: { code: number, message: string } | null
    deleteQuestion: { code: number, message: string } | null
}

const initialState: questionSliceStates = {
    loading: false, statusLoading: false, deleteLoading: false,
    createQuestion: null, getAllQuestions: null, getQuestionByID: null,
    updateQuestion: null, questionCurrentStatus: null, deleteQuestion: null
}

const questionSlice = createSlice({
    name: "questionSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getAllQuestionsAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getAllQuestionsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllQuestions = action.payload
        })
        builder.addMatcher(isAnyOf(getAllQuestionsAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(createQuestionAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(createQuestionAsync.fulfilled), (state, action) => {
            state.loading = false
            state.createQuestion = action.payload
        })
        builder.addMatcher(isAnyOf(createQuestionAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(getQuestionByIDAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getQuestionByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getQuestionByID = action.payload?.data
        })
        builder.addMatcher(isAnyOf(getQuestionByIDAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(updateQuestionAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(updateQuestionAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateQuestion = action.payload
        })
        builder.addMatcher(isAnyOf(updateQuestionAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(questionCurrentStatusUpdateAsync.pending), (state, action) => {
            state.statusLoading = true
        })
        builder.addMatcher(isAnyOf(questionCurrentStatusUpdateAsync.fulfilled), (state, action) => {
            state.statusLoading = false
            state.questionCurrentStatus = action.payload
        })
        builder.addMatcher(isAnyOf(questionCurrentStatusUpdateAsync.rejected), (state, action) => {
            state.statusLoading = false
        })
        builder.addMatcher(isAnyOf(deleteQuestionAsync.pending), (state, action) => {
            state.deleteLoading = true
        })
        builder.addMatcher(isAnyOf(deleteQuestionAsync.fulfilled), (state, action) => {
            state.deleteLoading = false
            state.deleteQuestion = action.payload
        })
        builder.addMatcher(isAnyOf(deleteQuestionAsync.rejected), (state, action) => {
            state.deleteLoading = false
        })
    },
    reducers: {
        emptyQuestion: () => initialState,
        emptyDeleteQuestion: (state, action) => { state.deleteQuestion = null },
        emptyQuestionCurrentStatus: (state, action) => { state.questionCurrentStatus = null }
    }
})

export const { emptyQuestion, emptyDeleteQuestion, emptyQuestionCurrentStatus } = questionSlice.actions;
export default questionSlice.reducer;
