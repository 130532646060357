import { FormikProps } from 'formik'

async function uploadBinary<initialState>(
    path: string,
    pathType: "absolute" | "relative",
    Formik: FormikProps<initialState>,
    name: keyof initialState,
) {
    if (pathType === "absolute") {
        try {
            const imageURL = path

            // Taking out file name and extension
            const urlEncode = imageURL.split("//")
            const imageName = urlEncode[urlEncode?.length - 1]
            const imageExtension = imageName?.split(".").pop()

            // Creating blob
            const file = new File([imageURL], imageName, { type: `image/${imageExtension}` });

            const newFile = Object.assign(file, {
                preview: imageURL,
            });
            if (file) { Formik.setFieldValue(name as string, [newFile]) }
            else { return [] }
        }
        catch (error) { console.log("somthing Went Wong", error) }
    }
    if (pathType === "relative") {
        try {
            const imageBaseURL = process.env.REACT_APP_Image_URL

            // Creating Complete Path:::
            const imageURL = `${imageBaseURL}${path}`
            const response = await fetch(imageURL)

            // Taking out file name and extension
            const urlEncode = response?.url?.split("/")
            const imageName = urlEncode[urlEncode?.length - 1]
            const imageExtension = imageName?.split(".")[1]

            // Creating blob
            const blob = await response.blob();
            const file = new File([blob], imageName, { type: `image/${imageExtension}` });

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) { Formik.setFieldValue(name as string, [newFile]) }
            else { return [] }
        }
        catch (error) { console.log("somthing Went Wong", error) }
    }

}


export default uploadBinary;