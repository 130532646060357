import { configureStore } from '@reduxjs/toolkit';

// authentication:::
import authLoginSlice from '../slice/auth/authLoginSlice';
import userOperationsSlice from '../slice/auth/userOperationsSlice';
import authGuestUserSlice from '../slice/auth/authGuestUserSlice';
import authRegistrationSlice from '../slice/auth/authRegistrationSlice';
import authLoginWithGoogleSlice from '../slice/auth/authLoginWithGoogle';
import getCurrentUserDetailsSlice from '../slice/auth/getCurrentUserDetailsSlice';
import saveCurrentUserDetailsSlice from '../slice/auth/saveCurrentUserDetailsSlice';
// IsError:::
import IsErrorSlice from '../axiosClient/IsErrorSlice';
// Common:::
import examinerSlice from '../slice/common/examiner/examinerSlice';
import examSlice from '../slice/common/exam/examSlice';
import syllabusSlice from '../slice/common/syllabus/syllabusSlice';
import syllabusUtilitySlice from '../slice/common/syllabus/syllabusUtilitySlice';
import questionSlice from '../slice/common/question/questionSlice';
import vacanciesSlice from '../slice/common/vacancies/vacanciesSlice';
import currentAffairsSlice from '../slice/common/currentAffairs/currentAffairsSlice';
import currentAffairCategoriesSlice from '../slice/common/currentAffairs/currentAffairsCategories';
import currentAffairGeographicalFocusSlice from '../slice/common/currentAffairs/currentAffairGeographicalFocus';
import quizSlice from '../slice/common/quiz/quizSlice';
import testUtilitySlice from '../slice/common/test/testUtilitySlice';
import testSeriesSlice from '../slice/common/test/testSeries/testSeriesSlice';
import testPaperSlice from '../slice/common/test/testPaper/testPaperSlice';
// Master User Record For Super Admin Panel
import usersRecordSlice from '../slice/superAdmin/usersRecord/usersRecordSlice';


export const store = configureStore({
    reducer: {
        registration: authRegistrationSlice,
        login: authLoginSlice,
        googleLogin: authLoginWithGoogleSlice,
        guestUser: authGuestUserSlice,
        userOperations: userOperationsSlice,
        currentUserDetails: getCurrentUserDetailsSlice,
        saveCurrentUserDetails: saveCurrentUserDetailsSlice,
        isError: IsErrorSlice,
        examiners: examinerSlice,
        exam: examSlice,
        syllabus: syllabusSlice,
        syllabusUtility: syllabusUtilitySlice,
        question: questionSlice,
        masterUsersRecord: usersRecordSlice,
        vacancies: vacanciesSlice,
        currentAffairs: currentAffairsSlice,
        currentAffairCategories: currentAffairCategoriesSlice,
        currentAffairGeographicalFocus: currentAffairGeographicalFocusSlice,
        quiz: quizSlice,
        testUtility: testUtilitySlice,
        testSeries: testSeriesSlice,
        testPaper: testPaperSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

// <----- Setting "type of store" & "type for dispatch" from store state & store dispatch respectivelly
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch