import { Components } from '@mui/material/styles'

const MuiSelect: Components['MuiSelect'] = {
    styleOverrides: {},
    defaultProps: {
        size: "small",
        SelectDisplayProps: { style: { fontFamily: 'Poppins' } },
        MenuProps: { style: { fontFamily: 'Poppins' } },
    },
};

export default MuiSelect;
