import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";

// Create Current Affair:::
export const createCurrentAffairAsync = createAsyncThunk(
    "createCurrentAffair/current-affairs", async (payload: FormData, toolkit) => {
        return await AxiosClient("POST", "/current-affairs", payload, toolkit)
    }
)

// Get All Current Affairs:::
interface currentAffairParams { page?: number, limit?: number, search?: string | null, geographicalFocus?: string | null, category?: string | null, status?: "active" | "inactive" }
export const getAllCurrentAffairsAsync = createAsyncThunk(
    "getAllCurrentAffairs/current-affairs", async (params: currentAffairParams, toolkit) => {
        return await AxiosClient("GET", "/current-affairs", [], toolkit, params)
    }
);

// Get By ID:::
export const getCurrentAffairByIDAsync = createAsyncThunk(
    "getCurrentAffairByID/current-affairs", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("GET", `/current-affairs/${payload.id}`, [], toolkit)
    }
)

// Update Current Affair:::
export const updateCurrentAffairAsync = createAsyncThunk(
    "updateCurrentAffair/current-affairs", async (payload: { id: number, formData: FormData }, toolkit) => {
        return await AxiosClient("POST", `/current-affairs/${payload.id}`, payload.formData, toolkit)
    }
)

// Active/Inactive Status:::
export const currentAffairCurrentStatusUpdateAsync = createAsyncThunk(
    "currentAffairCurrentStatus/current-affairs/status", async (payload: { id: number, status: "active" | "inactive" }, toolkit) => {
        return await AxiosClient("POST", "/current-affairs/update/status", payload, toolkit)
    }
)

// Delete Current Affair:::
export const deleteCurrentAffairAsync = createAsyncThunk(
    "deleteCurrentAffair/current-affairs", async (payload: { id: number }, toolkit) => {
        return await AxiosClient("DELETE", `/current-affairs/${payload.id}`, [], toolkit)
    }
)