export const fileFormats = {
    pdf: { "application/pdf": [] },
    docx: {
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": []
    },
    images: {
        "image/jpeg": [], "image/png": [], "image/gif": [],
        "image/webp": [], "image/svg+xml": [],
    }
}

// application/msword allows only .doc files, which are older Word files.

// application/vnd.openxmlformats-officedocument.wordprocessingml.document allows only .docx files, which are the newer format and more commonly used today.