import React, { Fragment, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

import { menuList } from './components/menuList'

import Modal from '../../components/Dialog/Modal'
import Authentication from '../../auth/Authentication'
import BottomNavigationDrawer from './BottomNavigationDrawer'

function BottomNavBar() {
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname, state } = location

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [value, setValue] = useState<number>(() => {
        const storedValue = localStorage.getItem('bottom-nav-value');
        return storedValue ? Number(storedValue) : 0;
    })
 
    useMemo(() => {
        localStorage.setItem('bottom-nav-value', String(value));
    }, [value])

    useMemo(() => {
        if (state) {
            setValue(state.indexValue)
            navigate(location.pathname, { replace: true, state: null });
        }
    }, [state, navigate, location.pathname]);

    const activeMenuIdentifire = (menuName: string) => {
        const currentMenu = menuList?.find((item) => item.name === menuName)
        if (currentMenu?.component === "modal") {
            return isOpen;
        }
        if (currentMenu?.component === "page" && !currentMenu?.subList.length && pathname === currentMenu.path) {
            return true;
        }
        if (currentMenu?.component === "page" && currentMenu?.subList.length) {
            return currentMenu?.subList.some((sub) => sub.path === pathname);
        }
    }
    
    return (
        <Fragment>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                showLabels={false}
            >
                {
                    menuList?.length && menuList.map((item, index) => {
                        return (
                            <BottomNavigationAction
                                key={item.id}
                                icon={<item.icon sx={{ zIndex: '10', color: activeMenuIdentifire(item.name) ? 'white' : '' }} />}
                                onClick={() => item.path === '/login' ? setIsOpen(true) : item.path !== "" && navigate(item.path)}
                            />
                        )
                    })
                }
            </BottomNavigation>
            {
                menuList[value] ?
                    <BottomNavigationDrawer
                        name={menuList[value].name}
                        subMenu={menuList[value].subList}
                        icon={menuList[value].icon}
                        currentMenu={value}
                    />
                    : null
            }

            <Modal {...{ isOpen, setIsOpen }}>
                <Authentication />
            </Modal>
        </Fragment>
    )
}

export default BottomNavBar;
