import { lazy } from 'react'


// User Components :::
export const Dashboard = lazy(() => import("../../../pages/userPanel/dashboard/Dashboard"))

// Questions :::
export const Questions = lazy(() => import("../../../pages/userPanel/questions/Questions"))

// Exams :::
export const Exams = lazy(() => import("../../../pages/userPanel/exams/Exams"))

// Vacancies :::
export const Vacancies = lazy(() => import("../../../pages/userPanel/vacancies/Vacancies"))

// Others :::
export const Others = lazy(() => import("../../../pages/userPanel/other/Others"))


// 404 Component:::
export const PageNotFound = lazy(() => import("../../../layout/404/PageNotFound"))
