import { createSlice } from '@reduxjs/toolkit'

import { completeUserList, userRecordByID } from './interface'
import { getAllusersAsync, getUsersByIDAsync } from './usersRecordAsync'


interface usersSliceStates {
    loading: boolean
    getAllUsers: completeUserList | null
    getUserByID: userRecordByID | null
}

const initialState: usersSliceStates = {
    loading: false,
    getAllUsers: null,
    getUserByID: null,
}

const usersRecordSlice = createSlice({
    name: "usersRecord",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getAllusersAsync.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getAllusersAsync.fulfilled, (state, action) => {
            state.loading = false
            state.getAllUsers = action.payload
        })
        builder.addCase(getAllusersAsync.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getUsersByIDAsync.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getUsersByIDAsync.fulfilled, (state, action) => {
            state.loading = false
            state.getUserByID = action.payload
        })
        builder.addCase(getUsersByIDAsync.rejected, (state, action) => {
            state.loading = false
        })
    },
    reducers: {
        emptyUsersRecord: () => initialState
    }
})


export const { emptyUsersRecord } = usersRecordSlice.actions;
export default usersRecordSlice.reducer;
