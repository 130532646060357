import { CSSProperties } from 'react'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material/styles'

import { truncateText } from '../../../../custom/richTextEditor'

export const SideBar = styled(Paper)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    position: 'fixed', top: 0, left: 0,

    zIndex: 100,
    height: '100%',
    transition: '1s all',
    padding: '0.5rem 1rem',
    width: isOpen ? '16.7rem' : '5rem',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const SiteLogoName = styled(Stack)(({ theme }) => ({
    display: 'flex',
    marginTop: '13px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

export const SiteName = styled(Typography)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    fontSize: '18px',
    fontWeight: '700',
    padding: '0 0 0 1rem',
    opacity: isOpen ? 1 : 0,
    color: theme.palette.primary.main,
    transition: isOpen ? '0.8s all' : '0.2s all',
}));

export const BurgerContainer = styled(Stack)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    position: 'absolute',
    top: 'calc(3rem / 1.5)',
    left: isOpen ? '15.8rem' : '4rem',

    zIndex: 1,
    cursor: 'pointer',
    borderRadius: '2rem',
    transition: '1s all',
    width: '1.875rem', height: '1.875rem',
    backgroundColor: theme.palette.primary.main,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const burgerIconStyles = { color: 'white', fontSize: '30px', fontWeight: '800', transition: '0.5s all' }

export const MenuContainer = styled(Stack)(({ theme }) => ({
    width: '107%',
    minHeight: '24.375rem',
    height: 'calc(100vh - 50px)',

    margin: '1rem 0 0 0',
    overflowY: 'scroll',
    overflowX: 'hidden',

    "&::-webkit-scrollbar": { width: '5px !important', height: '5px !important' },
    "&::-webkit-scrollbar-track": { background: 'transparent' },
    "&::-webkit-scrollbar-thumb": { background: theme.palette.gradients.primary, borderRadius: "25px" }
}));

export const MenuList = styled(List)(({ theme }) => ({
    margin: 0,
    padding: 0,
    display: 'flex',
    listStyle: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
}));

export const MenuListItems = styled(ListItem)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    margin: '0.1rem 0',
    padding: '0.5rem 0',
    borderRadius: '1rem',
}));

export const Modules = styled(Link)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    width: '78%',
    color: 'inherit',
    cursor: 'pointer',
    padding: '0 0 0 1.5rem',
    opacity: isOpen ? 1 : 0,
    display: isOpen ? 'flex' : 'none',
    visibility: isOpen ? 'visible' : 'hidden',
    transition: isOpen ? 'opacity 1.5s ease-in-out, visibility 1s 1s ease-in-out, display 1s 1s ease-in-out'
        : 'opacity 0s ease-in-out, visibility 0s',
}));

export const SubMenuContainer = styled(Stack)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

export const subMenuItemsStyles: CSSProperties = {
    color: 'inherit',
    transition: '0.5s all',
    textDecoration: 'none',
    padding: '0rem 0 0.4rem 1.2rem',

    display: 'flex',
    columnGap: '30px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
};

export const ProfileContainer = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    gap: '20px',
    padding: '1.5rem 0 0 0',
}));

export const NameEmailBox = styled(Box)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    visibility: 'visible',
    opacity: isOpen ? 1 : 0,
    transition: isOpen ? 'opacity 3s ease-in-out, visibility 2s 2s' : '0.2s all',
}));

export const NameField = styled(Typography)(({ theme }) => ({
    margin: 0,
    padding: 0,
    fontWeight: '600',
    fontSize: '16.7px',
    textTransform: 'capitalize',
}));

export const EmailField = styled(Typography)(({ theme }) => ({
    margin: 0,
    padding: 0,
    fontSize: '12px',
    textTransform: 'lowercase',
}));

export const AccountActionContainer = styled(Stack)(({ theme }) => ({
    marginLeft: '-5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

export const AccountActionBox = styled(Box)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    opacity: isOpen ? 1 : 0,
    width: isOpen ? '100%' : '0px',
    visibility: isOpen ? 'visible' : 'hidden',
    transition: isOpen ? 'width 2s ease-in-out, opacity 2.5s ease-in-out, visibility 1s 1s'
        : 'width 0s ease-in-out, opacity 0s ease-in-out, visibility 0s',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
}));
