import React, { Fragment } from 'react'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'

import Loader from '../../components/Loader/Loader'

interface stateTextInputProps {
    label?: string
    value: string
    name?: string
    title?: string
    error?: boolean
    loading?: boolean
    disabled?: boolean
    placeholder?: string
    shouldShrink?: boolean
    sx?: React.CSSProperties
    setValue: (data: string) => void
    type?: React.HTMLInputTypeAttribute
}

function StateTextInput(props: stateTextInputProps) {
    const { disabled, error, name, label, loading, placeholder, setValue, shouldShrink, sx, title, type, value } = props;

    return (
        <Fragment>
            <Typography>{title}</Typography>
            <FormControl fullWidth disabled={loading}>
                <TextField
                    fullWidth
                    name={name}
                    value={value}
                    error={error}
                    disabled={disabled}
                    type={type || "text"}
                    placeholder={placeholder}
                    inputProps={{ style: { ...sx } }}
                    InputLabelProps={{ shrink: shouldShrink }}
                    onChange={(event) => setValue(event.target.value)}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                />
            </FormControl>

        </Fragment>
    )
}

export default StateTextInput;