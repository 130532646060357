import React, { Fragment, useEffect, useContext } from 'react'
import classes from '../styles/greetingSide.module.css'
import 'aos/dist/aos.css'
import Aos from 'aos'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface greetingSideProps {
    heading: string
    subHeading: string
    children: React.ReactNode
}
export default function GreetingSide({ children, heading, subHeading }: greetingSideProps) {

    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])

    return (
        <Fragment>
            <Stack className={classes.greetingContainer}>
                <Box className={classes.greetingTextBox} data-aos="fade-up">
                    <Typography className={classes.heading}>{heading}</Typography>
                    <Typography className={classes.subHeading}>{subHeading}</Typography>
                </Box>
                <Box data-aos="fade-down">
                    {children}
                </Box>
            </Stack>
        </Fragment>
    )
};
