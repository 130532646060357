import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { authRegistration } from './authAsync';

interface authRegistrationState {
  loading: boolean
  responseData: { code: number | null, message: string }
}

const initialState: authRegistrationState = {
  loading: false,
  responseData: { code: null, message: "" }
}

const authRegistrationSlice = createSlice({
  name: "authRegistrationSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase((authRegistration.pending), (state, action) => {
      state.loading = true
    })
    builder.addCase((authRegistration.fulfilled), (state, action) => {
      state.loading = false
      state.responseData = action.payload
    })
    builder.addCase((authRegistration.rejected), (state, action) => {
      state.loading = false
    })
  },
  reducers: { emptyAuthRegistrationState: () => initialState },
})

export const { emptyAuthRegistrationState } = authRegistrationSlice.actions;
export default authRegistrationSlice.reducer;