import { createSlice } from '@reduxjs/toolkit';
import { AxiosClient } from "../../../axiosClient/AxiosClient";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { APIStatus, paginationData } from '../common';

// Geographical Focus :::
export const geographicalFocus = [
    { id: 1, label: "International", value: "international" },
    { id: 2, label: "National", value: "national" },
    { id: 3, label: "State", value: "state" },
];


// Get All Current Affairs Geographical Focus :::
interface geographicalFocusParams { search?: string | null, page?: number, limit?: number, status?: "active" | "inactive" }
export const getAllCurrentAffairgeographicalFocusAsync = createAsyncThunk(
    "getAllCurrentAffairgeographicalFocus/geographical", async (params: geographicalFocusParams, toolkit) => {
        return await AxiosClient("GET", "/geographical", [], toolkit, params)
    }
);

export type geographicalFocusOptions = "international" | "national" | "state"

export interface geographicalFocusStructure {
    id: number, label: string, value: geographicalFocusOptions
    status: string, created_at: string, updated_at: string
}

interface allCurrentAffairGeographicalFocus extends APIStatus {
    data: geographicalFocusStructure[]
    pagination: paginationData
}

interface currentAffairsGeographicalFocusStates {
    loading: boolean
    getAllCurrentAffairGeographicalFocus: allCurrentAffairGeographicalFocus | null
}

const initialState: currentAffairsGeographicalFocusStates = {
    loading: false,
    getAllCurrentAffairGeographicalFocus: null
}

const currentAffairGeographicalFocusSlice = createSlice({
    name: "currentAffairGeographicalFocusSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase((getAllCurrentAffairgeographicalFocusAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addCase((getAllCurrentAffairgeographicalFocusAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getAllCurrentAffairGeographicalFocus = action.payload
        })
        builder.addCase((getAllCurrentAffairgeographicalFocusAsync.rejected), (state, action) => {
            state.loading = false
        })
    },
    reducers: {
        emptyCurrentAffairGeographicalFocus: () => initialState,
    }
})

export const { emptyCurrentAffairGeographicalFocus } = currentAffairGeographicalFocusSlice.actions;
export default currentAffairGeographicalFocusSlice.reducer;