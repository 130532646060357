import { Components } from '@mui/material/styles'

const MuiAutocomplete: Components['MuiAutocomplete'] = {
    styleOverrides: {
        input: { fontFamily: "Poppins" },
        listbox: {
            gap: "5px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "Poppins",
            padding: "5px 2px 5px 12px",
            justifyContent: "flex-start",
        },
        option: {
            margin: 0,
            fontSize: "15px",
            alignSelf: "end",
            borderRadius: "10px",
            fontFamily: "Poppins",
            border: "0.1px solid #DCE0E4",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }
    },
    defaultProps: { size: "small" },
};

export default MuiAutocomplete;