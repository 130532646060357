import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'

import Loader from '../../components/Loader/Loader'

interface singleSelectProps<FormValues> {
    label: string
    title?: string
    loading: boolean
    disabled?: boolean
    name: keyof FormValues
    handleClick?: () => void
    Formik: FormikProps<FormValues>
    dropdown: { id: number, label: string, value: string | number }[]
}
function SingleSelect<FormValues>(props: singleSelectProps<FormValues>) {
    const { disabled, dropdown, handleClick, Formik, name, label, loading, title } = props
    return (
        <Fragment>
            <Typography>{title}</Typography>
            <FormControl fullWidth disabled={loading} size='small'
                error={Boolean(Formik.touched[name] && Formik.errors[name])}
            >
                <InputLabel size='small'>
                    {loading ? (<Loader padding="0 0" size={20} />) : label}
                </InputLabel>

                <Select
                    label={label}
                    disabled={disabled}
                    name={name as string}
                    value={Formik.values[name]}
                    onChange={(event) => { Formik.handleChange(event); handleClick && handleClick() }}
                >
                    <MenuItem value="" >Select {label} </MenuItem>
                    {
                        dropdown?.length ? dropdown.map((ele) => {
                            return (
                                <MenuItem key={ele.id} value={ele.value}>{ele.label}</MenuItem>
                            )
                        }) : null
                    }
                </Select>
            </FormControl>
        </Fragment >
    )
}

export default SingleSelect