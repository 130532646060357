import React, { Fragment } from 'react'
import { FormikProps } from 'formik'

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import Loader from '../../components/Loader/Loader'

interface authPasswordFieldProps<initialState> {
    label: string
    loading: boolean
    disabled?: boolean
    placeholder?: string
    showPassword: boolean
    sx: React.CSSProperties
    name: keyof initialState
    Formik: FormikProps<initialState>
    setShowPassword: (status: boolean) => void
}

function AuthPasswordField<initialState>(props: authPasswordFieldProps<initialState>) {
    const { name, label, showPassword, setShowPassword, Formik, loading, disabled, placeholder, sx } = props
    return (
        <Fragment>
            <FormControl fullWidth>
                <TextField
                    fullWidth
                    sx={{ ...sx }}
                    disabled={disabled}
                    name={name as string}
                    placeholder={placeholder}
                    value={Formik.values[name]}
                    onChange={Formik.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{ cursor: 'pointer' }}
                            >
                                {showPassword ?
                                    <VisibilityOffIcon color='primary' /> :
                                    <VisibilityIcon color='primary' />}
                            </InputAdornment>
                        )
                    }}
                    type={showPassword ? 'text' : 'password'}
                    error={Boolean(Formik.touched[name] && Formik.errors[name])}
                    label={loading ? (<Loader padding="0 0" size={20} />) : label}
                />
            </FormControl>
        </Fragment>
    )
}

export default AuthPasswordField;